import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import './Lista.css';


export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", uf_codigo: "", cidade_codigo: "", unidade_id: "", data_ini: "", data_fim: ""});
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const _userRef = useRef();
    const _searchRef = useRef(search);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);



    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        _searchRef.current.data_ini = moment().startOf('month').format('YYYY-MM-DD');
        _searchRef.current.data_fim = moment().endOf('month').format('YYYY-MM-DD');
        setSearch(_searchRef.current);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        _searchRef.current = search;
    }, [search]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            await Promise.resolve(new Request().Run("/json/estados.json", "GET", '', ''))
                .then((data) => {
                    setEstados(data);
                });
            
            _HandlerSearchChange(null);
        }
    }
    //#region Init


    //#region Handlers
    const _HandlerChange = (event) => {
        let _search = {...search}; //Object.assign({}, search);
        _search[event.target.id] = event.target.value;


        if(event.target.id === "uf_codigo") {
            window.w_loadObj("#cidade_codigo");
            Promise.resolve(new Request().Run("api/Atividades/Cidades", "POST", { uf_codigo: _search.uf_codigo }, user.token))
                .then((data) => {
                    setCidades(data);
                    window.w_unloadObj("#cidade_codigo");
                });

           _search.cidade_codigo = "";
           _search.unidade_id = "";
           window.w_loadObj("#unidade_id");
            Promise.resolve(new Request().Run("api/Atividades/Unidades", "POST", { uf_codigo: _search.uf_codigo, cidade_codigo: ""}, user.token))
            .then((data) => {
                setUnidades(data);
                window.w_unloadObj("#unidade_id");
            });   
        } else if(event.target.id === "cidade_codigo") {
            _search.unidade_id = "";
            window.w_loadObj("#unidade_id");
            Promise.resolve(new Request().Run("api/Atividades/Unidades", "POST", { uf_codigo: _search.uf_codigo, cidade_codigo: _search.cidade_codigo}, user.token))
            .then((data) => {
                setUnidades(data);
                window.w_unloadObj("#unidade_id");
            }); 
        }

        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Atividades/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { cidade_codigo: search.cidade_codigo, uf_codigo: search.uf_codigo, unidade_id: search.unidade_id, data_ini: search.data_ini, data_fim: search.data_fim, word: search.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                listRef.current = listRef.current.concat(data.list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            }).catch(()=> {
                setIsLoading(false);
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Atividades/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { cidade_codigo: search.cidade_codigo, uf_codigo: search.uf_codigo, unidade_id: search.unidade_id, data_ini: search.data_ini, data_fim: search.data_fim, word: search.word }, user.token))
                .then((data) => {
                    listRef.current = listRef.current.concat(data.list);
                    setList(listRef.current);
                    setIsLoading(false);
                }).catch(()=> {
                    setIsLoading(false);
                });
        } catch (e) { }
    }



    const _UnidadeClick = (item) => {
        
    }
    //#endregion Handlers




    return (
        <div className="AtividadesLista">
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Lista de Atividades</h5>
                            <label className="label label-default pull-right btn"> { total } Atividades </label>
                        </div>
                        <div className="ibox-content">
                            <div className="input-group">
                                <input type="date" id="data_ini" value={search.data_ini} className="input" onChange={_HandlerChange} />
                                <input type="date" id="data_fim" value={search.data_fim} className="input" onChange={_HandlerChange} />

                                <span className="input-group-btn">
                                    <select id="uf_codigo" className="input form-control" value={search.uf_codigo} onChange={_HandlerChange}>
                                        <option value="">Qualquer Estado</option>
                                        {estados.map((item, i) => (
                                        <option key={'uf' + i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </span>

                                <span className="input-group-btn">
                                    <select id="cidade_codigo" className="input form-control" value={search.cidade_codigo} onChange={_HandlerChange}>
                                        <option value="">Qualquer Cidade</option>
                                        {cidades.map((item, i) => (
                                        <option key={'cidade' + i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </span>

                                <span className="input-group-btn">
                                    <select id="unidade_id" className="input form-control" value={search.unidade_id} onChange={_HandlerChange}>
                                        <option value="">Qualquer Unidade</option>
                                        {unidades.map((item, i) => (
                                        <option key={'unidade' + i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </span>

                                <span className="input-group-btn">
                                    <button id="btnBusca" type="button" className="btn btn btn-primary" onClick={_HandlerSearchChange}> <i className="fa fa-search"></i> Busca</button>
                                </span>
                            </div>
                        

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Unidade</th>
                                                <th>Servidor</th>
                                                <th>Camera</th>
                                                <th>Mensagem</th>
                                                <th>Data/Hora</th>
                                                <th>Usuário</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'unidade' + i}>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.unidade} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.server} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.camera} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.message} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {moment(item.data_cadastro).format('DD/MM/YYYY HH:mm')} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.user} </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

    
            </div>

        </div>
    );
}