import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import './Atividades.css';
import { HubObjects } from '../../../scripts/HubObjects';


export default function Atividades() {
    const component_name = "admin/sidebar/atividades";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    
    const _userPrestine = useRef(false);
    const _camOverId = useRef(0);
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

     //#region Effects
     useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearInterval(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if (user.token !== "" && !_userPrestine.current){
            _userPrestine.current = true;
        }
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
     
    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current){
            loadedRef.current = true;
            console.log("Atividades Initialized"); 
            
            let wait_observer = setInterval(()=> {
                if(HubObjects.ObserverExists("CAMERA_ACTIVITY_OBSERVER")) {
                    clearInterval(wait_observer);
                    HubObjects.Subscribe("CAMERA_ACTIVITY_OBSERVER", (acts) => {  
                        setActivities(acts);
                        forceUpdate();
                    });
                }
            }, 100)
        }    
    }
    //#endregion Init 




    //#region Handlers
    const _HandleCamOver = (item) => {
        if(item.camera_id !== _camOverId.current) {
            _camOverId.current = item.camera_id; 
            try {
                let markers = HubObjects.Get("HOME_MARKERS");
                let map = HubObjects.Get("HOME_MAP");
                let marq = markers.find(v => parseInt(v.pointObj.id) === parseInt(item.camera_id));
                map.panTo(marq.getPosition());
            } catch (e) { 
                console.log(e.message) ;
                //window.location.reload(false);
            }
        }
    }


    const  _HandleCamClick = (item) => {
        let _camera_id = item.camera_id;
        let _server_id =  HubObjects.Exec("HOME_COMPONENT_SERVERID_FROM_CAMERAID",_camera_id);
        if(_server_id !== undefined && _server_id !== null && _server_id !== "") {
            HubObjects.Exec("CAMERA_IMAGE64_FROM_SERVER" + _server_id, _camera_id);
        }
     }
     
    //#endregion Handlers


    return (
        <div className="AtividadesTab">
            { activities.length > 0 ? 
            (   <div>
                {
                    activities.map((item, i) => (
                    <div key={'activity'+i} className="atividade animated fadeInRight" onClick={()=> _HandleCamClick(item)} onMouseOver={() => _HandleCamOver(item)}>
                        <div className="atividade_icon"><img src="/img/camera42x42.png" alt=""/> </div>
                        <div> 
                            <div className="atividade_name">{item.name}</div>
                            <div className="atividade_description">{item.message}</div>
                            <div className="label small">{moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}</div>
                        </div>
                    </div>
                   ))
                }
                </div>
            ) : 
            (<div className='jumbotron'><center><i className="fa fa-video-camera fa-2x"></i><br /><span style={{padding:'5px'}}>Nenhuma atividade detectada</span></center></div>)
            }
        </div>
    );
}
