/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import './Config.css';
import { HubObjects } from '../../../scripts/HubObjects'



export default function Config() {
    const component_name = "admin/sidebar/config";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [cameraId, setCameraId] = useState("");
    const [unidades, setUnidades] = useState([]);
    const navigate = useNavigate();

    const _formCameraRef = useRef();
    const _cameraIdRef = useRef("");
    const _userRef = useRef();
    const _userPrestine = useRef(false);
    const _cameraDataRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearInterval(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if (user.token !== "" && !_userPrestine.current){
            _userPrestine.current = true;
            _userRef.current = user;
            RegisterHub();
        }
        
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current){
            loadedRef.current = true;
            console.log("Config Initialized");

            RegisterHub();            
        }    
    }
    //#endregion Init 


    //#region Form Handlers
     const unidade_id = async(value) => {
        let unidade_id = "";
        try {
            await  _formCameraRef.current.Select("server_id", [], "api/Home/Servers", "POST", {unidade_id: value}, _userRef.current.token);
        } catch(e) { }
     }


    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formCameraRef.current !== null && _formCameraRef.current !== undefined) {
                    clearInterval(wait_token);

                    await  _formCameraRef.current.Select("unidade_id", [], "api/Users/Unidades", "POST", {}, _userRef.current.token);
                    let unidade_id = "";
                    try {
                        unidade_id = await  _formCameraRef.current.GetObject("unidade_id").type.options[0].id;

                    } catch(e) { }
                    
                    await  _formCameraRef.current.Select("server_id", [], "api/Home/Servers", "POST", {unidade_id: unidade_id}, _userRef.current.token);

                    if (_cameraIdRef.current !== "" && _cameraIdRef.current !== undefined && _cameraIdRef.current !== null) {
                        let camera_data = await new Request().Run("api/Home/Camera?camera_id=" +_cameraIdRef.current, "POST", {}, _userRef.current.token);
                        _cameraDataRef.current = camera_data;
                    }

                    await _formCameraRef.current.DisableFields(undefined, false);
                    //$("#edit_mode").prop("disabled", true);
                    $("#btn_save").prop("disabled", true);

                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }



    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("CONFIG_COMPONENT_NEW", () => _HandleNew(), component_name);


        HubObjects.Set("CONFIG_COMPONENT_UPDATE_SERVERS", (async() => {
            await  _formCameraRef.current.Select("server_id", [], "api/Home/Servers", "POST", {}, _userRef.current.token);
        }), component_name);


        HubObjects.Set("CONFIG_COMPONENT_UPDATE_LATLNG", (async(lat, lng) => {
            await  _formCameraRef.current.Value("latitude", lat);
            await  _formCameraRef.current.Value("longitude", lng);

            if(_cameraIdRef.current !== "") {
                Promise.resolve(new Request().Run("api/Home/SavePosition", "POST", { camera_id: _cameraIdRef.current, latitude: lat, longitude: lng}, user.token))
                    .then((data) => {
                        
                    });
            }
        }), component_name);


        HubObjects.Set("CONFIG_COMPONENT_CLEAR_FORM", (async() => {
            HubObjects.Exec("HOME_COMPONENT_ON_EDIT_MODE", _cameraIdRef.current, false);
            await  _formCameraRef.current.Clear();
            await  _formCameraRef.current.Select("unidade_id", [], "api/Users/Unidades", "POST", {}, _userRef.current.token);
            let unidade_id = "";
            try {
                unidade_id = await  _formCameraRef.current.GetObject("unidade_id").type.options[0].id;

            } catch(e) { }
            
            await  _formCameraRef.current.Select("server_id", [], "api/Home/Servers", "POST", {unidade_id: unidade_id}, _userRef.current.token);
            await  _formCameraRef.current.DisableFields();
            await  _formCameraRef.current.Value("motion_threshold", 1);
            _cameraIdRef.current = "";
            setCameraId("");
            $("#edit_mode").prop("disabled", true);
            $("#edit_mode").prop("checked", false);
            _cameraDataRef.current = {};
        }), component_name);


        HubObjects.Set("CONFIG_COMPONENT_MARKER_CLICK", (async(marker) => {
            await _formCameraRef.current.Clear(false);
            marker.camera_id = marker.id;
            const { ['id']: removedProperty, ...markerRest } = marker;
            _cameraIdRef.current = markerRest.camera_id;
            setCameraId(markerRest.camera_id);
            _cameraDataRef.current = markerRest;
            await _formCameraRef.current.DisableFields(undefined, true);
            await _formCameraRef.current.Load(markerRest);
            await _formCameraRef.current.Select("server_id", [], "api/Home/Servers", "POST", {unidade_id: markerRest.unidade_id}, user.token);
            $("#edit_mode").prop("disabled", false);
            $("#edit_mode").prop("checked", false);
            $("#btn_save").prop("disabled", false);
            HubObjects.Exec("HOME_COMPONENT_ON_EDIT_MODE", _cameraIdRef.current, false);
        }), component_name);
    }

    //#region HubObjects



    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 
        let _result = await _formCameraRef.current.Post("api/Home/CameraSave", {camera_id: _cameraIdRef.current}, user.token);
        setPageLoading(false);

        if(_result === undefined || _result === null) {
            window.swal("Alerta","Não foi possível salvar esta câmera!", "warning");
        }

        

        if (_result === "exists" || _result === "error" || _result.id === 0) {
            window.swal("Alerta","Camera já cadastrada!", "warning");
        } else if (_result === "cam_limit") {
            window.swal("Alerta","Seu limite de cameras chegou ao fim, efetue a troca de plano.", "warning");
        } else {
            _result.latitude = await _formCameraRef.current.GetValue("latitude");
            _result.longitude = await _formCameraRef.current.GetValue("longitude");
            
            _cameraIdRef.current = _result.id;
            setCameraId(_result.id)
            _cameraDataRef.current = _result;
            HubObjects.Exec("HOME_COMPONENT_ON_CAMERA_SAVE", _result)
        }
    }

    const _HandleNew = async() => {
        await _formCameraRef.current.DisableFields(undefined, true);
        //$("#edit_mode").prop("disabled", false);
        $("#btn_save").prop("disabled", false);
    }

    const _HandleEditMode = (event) => {
        HubObjects.Exec("HOME_COMPONENT_ON_EDIT_MODE", _cameraIdRef.current, event.target.checked);
    }
    //#endregion Handlers




    return (
        <div className="CameraConfig">
            <form name="fcamera" onSubmit={(event) => _HandleSubmit('camera', event)} autoComplete="off">
                <div className="row">
                    <div className="col-sm-1"><input id="edit_mode" type="checkbox" disabled={(cameraId==='')} onClick={_HandleEditMode}/></div>
                    <div className="col-sm-10" style={{marginTop:'2px'}}><span>Mover Camera</span></div>
                </div>
                <hr />
                <FormGenerator ref={_formCameraRef} name={'camera'} url={'/forms/camera.json'} execRef={Exec}></FormGenerator>
                <button id="btn_save" className="btn btn-primary" type="submit" style={{width:'100%',height:'40px'}} >Salvar Camera</button>
            </form>
            <br />
        </div>
    );
}