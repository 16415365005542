import React, { useContext, useState, useEffect, useRef} from 'react';
import $ from 'jquery';
import { HubObjects } from '../../../scripts/HubObjects';
import { Request }  from '../../../scripts/Request';
import './Renew.css';


const Renew = (props) => {
    const [frm, setFrm] = useState({ email: "", code: "", new_pwd: "", repeat_pwd: "", token: "" });
    const [state, setState] = useState(0); //0-entercode, 1-resetpwd
    const [pwdState, setPwdState] = useState({message: "", color:"#FFFFFF"});


    //#region Effects
    useEffect(() => {
        let _form = HubObjects.Get("RECOVERY_FORM");
        let _frm = {...frm}; //Object.assign({}, frm);
        _frm.email = _form.email;
        setFrm(_frm);
    }, []);
    //#endregion Effects



    //#region Handlers
    const _HandlerChange = (e) => {
        const { id, value } = e.target;

        setFrm(prevState => ({
            ...prevState,
            [id]: value
        }));

        if (id === "new_pwd") StrengthChecker(value);
    }


    const _HandlerCode = async (e) => {
        window.w_loadObj("#btnCode");
        await Promise.resolve(new Request().Run("api/Recovery/ValidateCode", "POST", frm, ''))
            .then((data) => {
                window.w_unloadAll();
                if (data.toString() === "error") {
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "warning");
                } else {
                    setState(1);
                    let _frm = {...frm}; //Object.assign({}, frm);
                    _frm.token = data;
                    setFrm(_frm);
                }
            });
    }


    const _HandlerSave = async (e) => {
        window.w_loadObj("#btnSave");
        await Promise.resolve(new Request().Run("api/Recovery/ChangePwd", "POST", frm, ''))
            .then((data) => {
                window.w_unloadAll();
                if (data.toString() === "error") {
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "warning");
                } else {
                    window.swal("Sucesso", "Sua nova senha foi salva!", "success");
                    props.onSuccess();
                    setState(0);
                }
            });
    }
    //#endregion Handlers


    //#region Functions
    const StrengthChecker = (pass) => {
        var score = scorePassword(pass);
        //console.log(score, pass);

        if (score >= 75) {
            setPwdState({ message: "Senha Forte", color: "#006600" });
        } else if (score > 50) {
            setPwdState({ message: "Senha Acentável", color: "#0000FF" });
        } else if (score >= 25) {
            setPwdState({ message: "Senha Fraca", color: "#FF0000" });
        } else if (score <= 5) {
            setPwdState({ message: "", color: "#FFFFFF" });
        }
    }


    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions


    return (
        <div className="Renew" >
            { state === 0 ? (<div>
            <div style={{ height: '45px', marginTop: '20px' }}>
                <center>
                    <span className="topTitle" style={{ color: '#8469EC' }}><h2>Código enviado</h2></span>
                </center>
            </div>

            <p>
                <h4>Digite o código de recuperação</h4>
                <input id="code" name="code" type="text" className="form-control" value={frm.code} onChange={_HandlerChange} autoComplete="off" />

                <div className="cad_pre_code">
                    Use o código enviado em seu e-mail para prosseguir ao próximo passo, caso não tenha recebido verifique a caixa de spam.
                </div>

                <button id="btnCode" className="btn btn-primary" style={{ width: '100%', height: '60px', marginTop: '25px' }} disabled={!(frm.code.length === 6)} onClick={_HandlerCode}> Conferir Código </button>

                <button id="btnBack" className="btn btn-white" style={{ width: '100%', height: '40px', marginTop: '15px' }} onClick={() => props.onCancel()}> Voltar </button>
            </p></div>) :
            
            (<div>
                <div style={{ height: '45px', marginTop: '20px' }}>
                <center>
                    <span className="topTitle" style={{ color: '#8469EC' }}><h2>Configurar Acesso</h2></span>
                </center>
            </div>

            <p>
                <h4>Digite a nova senha <span style={{ color: pwdState.color, float:'right' }}><small>{pwdState.message}</small></span></h4>
                <input id="new_pwd" name="new_pwd" type="password" className="form-control" value={frm.new_pwd} onChange={_HandlerChange} autoComplete="off" />

                <h4 style={{ marginTop: '20px' }}>Repetir a nova senha</h4>
                <input id="repeat_pwd" name="repeat_pwd" type="password" className="form-control" value={frm.repeat_pwd} onChange={_HandlerChange} autoComplete="off" />

                <h4 className={(frm.new_pwd.length >= 6 && frm.repeat_pwd.length >= 1 && frm.new_pwd != frm.repeat_pwd) ? '' :'hide'} style={{color:'red'}}> <center>As senhas não são iguais!</center> </h4>

    
                <button id="btnNew" className="btn btn-primary" style={{ width: '100%', height: '60px', marginTop: '25px' }} disabled={!(frm.new_pwd == frm.repeat_pwd && frm.new_pwd.length>=6)} onClick={_HandlerSave}> Salvar Nova Senha </button>

                <button id="btnBack" className="btn btn-white" style={{ width: '100%', height: '40px', marginTop: '15px' }} onClick={() => props.onCancel()}> Voltar </button>
            </p>
            </div>) }

        </div>
    )
}

export default Renew;