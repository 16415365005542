import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import { RequestGet } from '../../scripts/Request';
import { Currency } from '../../scripts/StringUtils';
import { SSL } from '../../scripts/SSL';
import Cadastro from './Cadastro';
import Reativar from './Reativar';
import Forget from './Recovery/Forget';
import Renew from './Recovery/Renew';
import './Login.css';



const Login = () => {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [planos, setPlanos]  = useState([]);
    const [state, setState] = useState({
        login: "",
        password: ""
    });
    const [recovery, setRecovery] = useState(0);
    const [isRenew, setIsRenew] = useState(false);

    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        //SSL.ToHTTPS();

        if(!loadedRef.current) {
            loadedRef.current = true;
            handleLogout(true);
            $(".login_ex").hide();
            LoadPlanos();
            Init();
        }
    }, []);


    useEffect(() => {
       if(loading){
            window.w_loadObj(".plogin");
       } else {
            window.w_unloadAll();
       }
    }, [loading]);
    //#endregion Effects


     //#region Core
     const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');

        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            setState(_u);
        }
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCuc6MBLlqvSNTiIqClRnb3ggIkKjMMg1I",
        libraries: ["places"]
    });
    //#endregion Core


    //#region Loaders
    const LoadPlanos = async() => {
        window.w_loadObj(".planos");
        Promise.resolve(RequestGet("/json/planos.json"))
            .then((data) => {
                setPlanos(data);
                window.w_unloadAll();
            });
    }
    //#endregion Loaders


    //#region Handlers
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }


    const _handleLogin = (event) => {
        event.preventDefault();
        const _state = Object.assign({}, state);
        const _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password });

        localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password }));

        handleLogin(state);
    };

    const _HandlerEyeClick = (event) => {
        $(".field-icon").toggleClass("fa-eye-slash");
        var input = $("#password");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    }


    const _HandlerCadSuccess = () => {
        Expand("login");
        setTimeout(() => {
            $(".painel").scrollTop(0);
        }, 200);
    }



    const _HandlerRenew = () => {
        setTimeout(() => {
           setIsRenew(true);
        }, 100);
    }


    const _HandleRecovery = (index) => {
        setRecovery(index);
    }


    //#endregion Handlers


    //#region Animations
    const ExpandTime = (id) => {
        setTimeout(() => {
            Expand(id);
        }, 200);
    }

    const Expand = (id) => {
        if(id === "presents") {
            $("#cadastro").hide();
            $("#presents").removeClass("col-lg-4");
            $("#presents").addClass("col-lg-8");
            $("#presents_p").addClass("painel-expand");
            $("#cadastro_p").removeClass("painel-expand");
            $(".presents_ex").hide();
            $(".login_ex").show();
        } else if( id === "cadastro") {
            $("#cadastro").show();
            $("#presents").hide();
            $("#cadastro").removeClass("col-lg-4");
            $("#cadastro").addClass("col-lg-8");
            $("#cadastro_p").addClass("painel-expand");
            $("#presents_p").removeClass("painel-expand");
            $(".cadastro_ex").hide();
            $(".login_ex").show();
        } else if( id === "login") {
            $("#presents").removeClass("col-lg-8");
            $("#presents").addClass("col-lg-4");
            $("#cadastro").removeClass("col-lg-8");
            $("#cadastro").addClass("col-lg-4");
            $("#presents_p").removeClass("painel-expand");
            $("#cadastro_p").removeClass("painel-expand");
            $("#cadastro").show();
            $("#presents").show();
            $(".login_ex").hide();
            $(".presents_ex").show();
            $(".cadastro_ex").show();
            $(".painel").scrollTop(0);
            setIsRenew(false);
        }

    }
    //#endregion Animations


    return (
    <React.Fragment>
    <div className="Login">
        <div className="painels row" > 
            <div id="presents" className="login-pad col col-lg-4" onClick={() => {Expand('presents')}}>
                <div className="text-center animated fadeInDown vertical-center">
                    <div id="presents_p" className="painel">
                        <div className="ibox ">
                        
                            <div className="ibox-content ibox-heading">
                                <h3> Vigilância em tempo real </h3>
                                <small> Inteligência artificial de monitoramento</small>
                            </div>
                            <div className="ibox-content">
                                <div className="feed-activity-list">

                                    <div className="feed-element">
                                        <div>
                                            <strong>Surveillance A.I.</strong> <div>É um software que concilia técnicas de inteligência artificial/visão computacional para monitorar cameras ip, 
                                            podendo assistir centenas* de cameras simultaneamente para emitir alertas de detecção de movimentos e categorização de objetos, pessoas e animais.</div>
                                        </div>
                                    </div>

                                    <div className="feed-element">
                                        <div>
                                            <strong>Vantagens</strong>
                                            <div>Não é preciso manter grandes equipes para monitorar centenas de cameras, a I.A. fica responsável por assistir todas as cameras deixando o técnico apenas com o monitoramento de notificações e tomadas de decisões importantes.</div>
                                        </div>
                                    </div>

                                    <div className="feed-element">
                                        <div>
                                            <strong>Plataforma para monitoramento</strong>
                                            <div>Através da plataforma <b>lifequery.org</b> vários servidores podem ser conectados simultaneamente possibilitando acompanhar varias localidades, tornando-se ideal para monitoramento individual ou multi-clientes para empresas de segurança.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <img src="/img/soft1.jpg" alt=""/>
                                <br /><strong>Monitoramento baseado em localização georreferenciada</strong>
                            </div>
                            <br /><br />
                            <div>
                                <img src="/img/soft2.jpg" alt=""/>
                                <br /><strong>Acesso a snapshots das cameras</strong>
                            </div>
                            <br /><br />
                            <div className="ibox-content">
                                <div className="feed-activity-list">

                                    <div className="feed-element">
                                        <div>
                                            <strong>Requisitos</strong> 
                                            <div>Todas cameras utilizadas devem possuir capacidade para streaming via IP na rede interna nos formato <b>mjpeg</b> ou <b>rtsp</b>, o software Surveillance A.I v2 deve ser instalado num computador dedicado
                                            para somente este fim conectado a mesma rede das cameras, recomenda-se conexão cabeada ethernet 10 Gbits ou superior, processadores i7/i9 ou r7/r9 com 8~16 GB de Ram.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feed-element">
                                        <div>
                                            <strong>Versão 2 - Beta</strong>
                                            <div>A versão 2 do Surveillance A.I. esta em fase Beta para parceiros, para ter acesso a todas as funcionalidades, faça seu cadastro e utilize 30 dias sem custo e compartilhe sua experiência conosco. </div>
                                            <center>
                                                <button type="button" className="btn block m-b btn-white" style={{ height:'40px', width:'230px'}} onClick={() => {ExpandTime('cadastro')}}>Ir para o Cadastro</button>
                                            </center>
                                        </div>
                                    </div>

                                    <div className="feed-element">
                                        <div>
                                            <small>* A quantidade de cameras dependem no numero de servidores físicos e suas configurações de hardware.</small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="presents_ex">Clique para expandir</span>
                </div>
            </div>


            <div id="login" className="login-pad col col-lg-4" onClick={() => {Expand('login')}}>
                <div className="text-center animated fadeInDown vertical-center">
                    { recovery === 0 ? (
                    <div className="painel plogin">
                        <div>
                            <div className="logo">
                                <img src="/img/logo.png" />
                            </div>
                        </div>
                        <form className="m-t" role="form">
                            <div className="form-group">
                                <input id="login" name="login" type="text" className="form-control" placeholder="e-mail" required="required" value={state.login} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <input id="password" name="password" type="password" className="form-control" placeholder="senha" required="required" value={state.password} onChange={handleChange} />
                                <span className="fa fa-fw fa-eye field-icon" onClick={_HandlerEyeClick}></span>
                            </div>
                            <button type="button" className="btn block full-width m-b" style={{ backgroundColor:'#8469EC', height:'40px', color:'#ffffff'}} onClick={_handleLogin}>Entrar</button>
                            <button type="button" className="btn block full-width m-b btn-white" style={{ height:'40px'}} onClick={() => _HandleRecovery(1)}>Esqueci minha senha</button>
                        </form>
                        <p className="m-t"> <small>Surveillance<b>AI</b> &copy; 2022</small> </p> 
                        
                    </div>) : (
                         recovery === 1 ? 
                         (<div className="painel plogin">
                            <Forget onRequest={() => _HandleRecovery(2)} onCancel={() => _HandleRecovery(0)}  />
                         </div> ) : 
                         ( 
                            <div className="painel plogin">
                                <Renew onSuccess={() => _HandleRecovery(0) } onCancel={() => _HandleRecovery(0)}/>
                            </div>

                         )
                    ) }
                    <span className="login_ex">Clique para centralizar</span>
                </div>
            </div>


            <div id="cadastro" className="login-pad col col-lg-4" onClick={() => {Expand('cadastro')}}>
                <div className="text-center animated fadeInDown vertical-center">
                    <div id="cadastro_p" className="painel" style={{ float: 'right'}}>
                        <div class="ibox-content text-center">
                            <h1>Cadastre-se agora</h1>
                            <div className="m-b-sm">
                                <img alt="image" class="rounded-circle" src="img/a8.jpg" />
                            </div>
                                    <p class="font-bold"><span style={{color:'#8070d4', fontSize:'26px'}}>30</span> dias para usar sem custo</p>

                            <div className="text-center">
                                <a href="#"  className="btn-pad btn btn-xs btn-white"><i className="fa fa-thumbs-up"></i> Começa agora </a>
                                <a className="btn-pad btn btn-xs btn-primary" href="https://pay.lifequery.org" target="_blank" /*onClick={_HandlerRenew}*/><i className="fa fa-podcast"></i> Reativar Conta </a>
                            </div>
                        </div>
                            
                        <div className="cad_pre_info">
                            <div>
                                <div>
                                    Participe da versão Beta e compartilhe sua experiência conosco para guiar a evolução do software e plataforma, preencha os dados no
                                    formulário abaixo e utilize todos recursos da plataforma gratuitamente por 30 dias em qualquer plano disponível. Consulte a lista de planos. 
                                </div>
                                <br></br>
                                { !isRenew ? 
                                    ( <Cadastro onSuccess={_HandlerCadSuccess} />) :
                                    ( <Reativar onSuccess={_HandlerCadSuccess} Title="Reativar seu cadastro" /> )
                                } 
                            </div>
                        </div>
                    </div>
                    <span className="cadastro_ex">Clique para expandir</span>
                </div>
            </div>
        </div>


        <div className="planos row">
            <div className="col col-lg-12">
                <div className="ibox ">
                    <div className="ibox-title">
                        <center><h5>Planos de Serviços</h5> (<small>Aplicáveis após o período gratuito</small>) </center>
                    </div>
                    <div className="ibox-content">

                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Plano</th>
                                <th>Valor</th>
                                <th>Recomendado para</th>
                            </tr>
                            </thead>
                            <tbody>
                                { planos.map((item, i) => (
                                <tr key={'plano'+i}>
                                    <td>{item.name}</td>
                                    <td>{Currency(item.valor)}</td>
                                    <td className="text-navy">{item.recomendado}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <center>
            Surveillance A.I. &copy; 2022 - Recomendado com Google Chrome &nbsp; - &nbsp; <i className="fa fa-envelope-o"></i> <a>contato@lifequery.org</a> - <i className="fa fa-whatsapp"></i> <a>(12) 98122-1043</a>
        </center>

        <br /><br />
    </div>
    </React.Fragment>)
};

export default Login;