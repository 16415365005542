import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { FilterObject } from '../../../scripts/ArrayUtils';
import './Cadastro.css';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [unidades, setUnidades] = useState([]);
    const [latlng, SetLatLng] = useState({ lat: -23.0286427, lng: -45.55022150000002 } );
    const navigate = useNavigate();

    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _userIdRef = useRef();
    const _userDataRef = useRef();
    const _userRef = useRef(user);
    const _unidadesRef = useRef([]);
    const _unidadesPrestine = useRef(false);

    let { UsuarioId } = useParams();

    //#region Effects
    useEffect(() => {
        if (UsuarioId) {
            _userIdRef.current = UsuarioId;
            setUserId(UsuarioId);
        }

        if(!_unidadesPrestine.current) {
            _unidadesPrestine.current = true;
            LoadUnidades();
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Loaders
    const LoadUnidades = async() => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Users/Unidades?nocache=" + new Date().getUTCMilliseconds(), "POST", { }, user.token))
            .then((data) => {
                data.map((item, i) => {
                    item.selected = false;
                });
                _unidadesRef.current = data;
                //setUnidades(data);
                setPageLoading(false);
            });
    }
    //#endregion Loaders


    //#region Form Handlers
    const SelectUnidades = (indexes) => {
        let wait_unidades = setInterval(() => {
            let _unidades = Object.assign({}, unidades);
            if (_unidadesRef.current.length > 0) {
                clearInterval(wait_unidades);

                indexes.map((item, i) => {
                    _unidadesRef.current.find(m => parseInt(m.id) === parseInt(item.id)).selected = true;
                });
                setUnidades(_unidadesRef.current);
            }
        }, 100);
    }


    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formUserRef.current !== undefined) {
                    clearInterval(wait_token);

                    
                    if (_userIdRef.current !== "" && _userIdRef.current !== undefined && _userIdRef.current != null) {
                        let user_data = await new Request().Run("api/Users/Load?user_id=" + _userIdRef.current, "POST", {}, _userRef.current.token);
                        _userDataRef.current = user_data;
                        await _formUserRef.current.Load(user_data);
                        
                        SelectUnidades(user_data.unidades);
                    } else {

                        await _formUserRef.current.Value("nivel", 1);
                        setUnidades(_unidadesRef.current);
                    }
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }



    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 

        let _unidades = [...unidades];
        let _selected_unidades = _unidades.filter((item) => {return item.selected === true});

        if(_selected_unidades.length == 0) {
            setPageLoading(false);
            window.swal("Alerta","Nenhuma unidade foi selecionada","warning");
            return;
        }

        let _result = await _formUserRef.current.Post("api/Users/Save", { user_id: userId, unidades: _selected_unidades}, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("E-mail já cadastrado!");
        } else {
            navigate('/Usuarios/Lista');
        }
    }


    const _HandleChange = (event, item) => {
        let _unidades = [...unidades];
        if (event.target.type === "checkbox") {
            _unidades.find(a => a.id === item.id).selected = event.target.checked;
        }
        setUnidades(_unidades);
    }
    //#endregion Handlers




   


    return (
        <div className="UsuarioCadastro">
            <div>
                <form name="fuser" onSubmit={(event) => _HandleSubmit('usuario', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Usuário</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg">
                                        <FormGenerator ref={_formUserRef} name={'user'} url={'/forms/user.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        <div style={{ paddingTop: '10px', paddingLeft:'5px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Usuário" type="submit" ><i className="fa fa-check"></i> Salvar Usuário</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Unidades</h5>
                                </div>

                                <div className="ibox-content">  
                                    {unidades.length > 0 ? 
                                    (<table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{width:'32px'}}></th>
                                                <th>Nome</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                            {unidades.map((item, i) => (
                                                <tr key={'unidade' + i}>
                                                    <td style={{padding:'7px 0px 0px 0px'}}> <input type="checkbox" checked={item.selected} style={{ width: '18px', height: '18px', marginLeft:'3px' }} onChange={(event) => _HandleChange(event, item)}/> </td>
                                                    <td> {item.name} </td>
                                                </tr>
                                            ))}
                                        
                                        </tbody>
                                    </table>) :
                                    (<div className='jumbotron'><center><i className="fa fa-bank fa-2x"></i><br /><span style={{padding:'5px'}}>Nenhuma unidade encontrada</span></center></div>)
                                    }
                                    
                                </div>
                            </div>           
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}