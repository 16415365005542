import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Request } from '../../scripts/Request';
import { FormGenerator } from '../Form/FormGenerator';
import { Uuid, RemoveAcentos } from '../../scripts/StringUtils';
import { Cache } from '../../scripts/Cache';
import './Reativar.css';




export default function Reativar(props) {

    const [pageLoading, setPageLoading] = useState(true);
    const [componentCad, setComponentCad] = useState(this);
    const [sessionId, setSessionId] = useState("");
    const [paymentMethods, setPaymentMethods] = useState("");
    const [existUser, setExistUser] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadedRef = useRef(false);

    const _sessionId = useRef("");
    const _cardsRef = useRef([]);
    const _dataRef = useRef({
        payment: {
            mode: "default",
            method: "creditCard",
            sender: {
                name: "",
                email: "",
                phone: {
                    areaCode: "",
                    number: ""
                },
                documents: [
                    {
                        document: {
                            type: "CPF",
                            value: ""
                        }
                    }
                ],
                hash: ""
            },
            currency: "BRL",
            notificationURL: "https://lifequery.org/api/Pagseguro/Notification",
            items: [
                {
                    item: {
                        id: "",
                        description: "",
                        quantity: 1,
                        amount: 0.00
                    }
                }
            ],
            extraAmount: 0.00,
            reference: "",
            shipping: {
                addressRequired: false
            },
            creditCard: {
                token: "",
                installment: {
                    quantity: 1,
                    value: 0.00
                },
                holder: {
                    name: "",
                    documents: [
                        {
                            document: {
                                type: "CPF",
                                value: ""
                            }
                        }
                    ],
                    birthDate: "00/00/0000",
                    phone: {
                        areaCode: "",
                        number: ""
                    },
                },
                billingAddress: {
                    street: "",
                    number: "1384",
                    complement: "",
                    district: "",
                    city: "",
                    state: "",
                    country: "BRA",
                    postalCode: ""
                }
            }
        }
    });


     //#region Effects
     useEffect(() => {
        if(pageLoading){
             window.w_loadObj(".App");
        } else {
             window.w_unloadAll();
        }
     }, [pageLoading]);


     useEffect(() => {
        if(!loadedRef.current){
            loadedRef.current = true;
            let wait_pgapi = setInterval( async() => {
                if (window.PagSeguroDirectPayment) {
                    clearInterval(wait_pgapi);
                    await LoadSessionId();
                } else {
                    console.log("Waiting PagSeguroDirectPayment");
                }
            }, 300);
        }
      
    }, []);


    useEffect(() => {
        if(!props.isOpen){
            try {
                _formUserRef.current.Clear();
                _formUserRef.current.DisableFields();
                _formUserRef.current.DisableFields(["email", "senha"], true);
            } catch(e) { }
        }
     }, [props.isOpen]);
    //#endregion Effects


    //#region Core Business
    const GetAmount = () => {
        let plano = _formUserRef.current.GetValue("plano_id");
        if (plano) {
            let tmp = plano.name.split("R$ ")[1];
            tmp = tmp.replace(",", ".");
            return tmp;
        }
        return "29.90";
    }


    const GetSenderHash = () => {
        window.PagSeguroDirectPayment.onSenderHashReady(function (response) {
            if (response.status === 'error') {
                console.log(response.message);
                return false;
            }
            _dataRef.current.payment.sender.hash = response.senderHash; //Hash estará disponível nesta variável.
        });
    }


    const createCardToken = (card) => {
        setPageLoading(true);

        plano_id(null);

        window.PagSeguroDirectPayment.createCardToken({
            cardNumber: card.cardNumber, // Número do cartão de crédito
            brand: card.brand, // Bandeira do cartão
            cvv: card.cvv, // CVV do cartão
            expirationMonth: card.expirationMonth, // Mês da expiração do cartão
            expirationYear: card.expirationYear, // Ano da expiração do cartão, é necessário os 4 dígitos.
            success: async (response) => {
                // Retorna o cartão tokenizado.
                _dataRef.current.payment.creditCard.token = response.card.token;
                _dataRef.current.payment.sender.name = _formUserRef.current.GetValue("name");
                _dataRef.current.payment.sender.email = _formUserRef.current.GetValue("email");

                let _phone = _formUserRef.current.GetValue("celular");
                _phone = _phone.replace("(", "").replace(")", "").replace("-", "");
                let _areaCode = _phone.split(' ')[0];
                let _number = _phone.split(' ')[1];

                _dataRef.current.payment.sender.phone.areaCode = _areaCode;
                _dataRef.current.payment.sender.phone.number = _number;
                _dataRef.current.payment.sender.documents[0].document.type = "CPF";
                _dataRef.current.payment.sender.documents[0].document.value = _formUserRef.current.GetValue("cpf").replaceAll(".", "").replaceAll("-", "");
                _dataRef.current.payment.reference = "lifequery_" + Uuid();
                _dataRef.current.payment.creditCard.holder.name = RemoveAcentos(_formUserRef.current.GetValue("cardName"));
                _dataRef.current.payment.creditCard.holder.documents[0].document.type = "CPF";
                _dataRef.current.payment.creditCard.holder.documents[0].document.value = _formUserRef.current.GetValue("cpf_holder").replaceAll(".", "").replaceAll("-", "");
                _dataRef.current.payment.creditCard.holder.phone.areaCode = _areaCode;
                _dataRef.current.payment.creditCard.holder.phone.number = _number;
                _dataRef.current.payment.creditCard.holder.birthDate = moment(_formUserRef.current.GetValue("birthDate")).format("DD/MM/YYYY");
                _dataRef.current.payment.creditCard.billingAddress.street = RemoveAcentos(_formUserRef.current.GetValue("rua"));
                _dataRef.current.payment.creditCard.billingAddress.number = _formUserRef.current.GetValue("numero");
                _dataRef.current.payment.creditCard.billingAddress.district = RemoveAcentos(_formUserRef.current.GetValue("bairro"));
                _dataRef.current.payment.creditCard.billingAddress.complement = RemoveAcentos(_formUserRef.current.GetValue("complemento"));
                _dataRef.current.payment.creditCard.billingAddress.state = _formUserRef.current.GetValue("uf_codigo").sigla;
                _dataRef.current.payment.creditCard.billingAddress.city = RemoveAcentos(_formUserRef.current.GetValue("uf_codigo").name);
                _dataRef.current.payment.creditCard.billingAddress.postalCode = _formUserRef.current.GetValue("cep").replaceAll(".", "").replaceAll("-", "");

                let _result = await _formUserRef.current.Post("api/Cadastro/Renew", _dataRef.current, '');
                setPageLoading(false);

                console.log(_result);
                if (_result === "loaded") {
                    window.swal({
                        title: "Sucesso",
                        text: "Compra finalizada, o acesso será liberado após processamento do pagamento!",
                        type: "success",
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        closeOnConfirm: true,
                        closeOnCancel: false
                    },
                     function(isConfirm){
                        props.onSuccess();
                    });
                    //history.push('/Login');
                } else if(_result === "exists") {
                    window.swal("Alerta", "Seu email é filiado a outra conta portanto não ter permissão para realizar compras.", "warning");
                } else if(_result === "cam_limit") {
                    window.swal("Alerta", "O plano escolhido tem menos cameras do que as cadastradas com seu usuário.", "warning");
                } else {
                    window.swal("Alerta", "Não foi possível processar o pagamento, confira as informações digitadas e tente novamente mais tarde.", "warning");
                    
                }
            },
            error: async (response) => {
                // Callback para chamadas que falharam.
                console.log(response);
                setPageLoading(false);
            },
            complete: async (response) => {
                // Callback para todas chamadas.
            }
        });
    }
    //#endregion Core Business


    //#region Loaders
    const LoadSessionId = async() => {
        await Promise.resolve(new Request().Run("api/Cadastro/Session", "GET", ""))
            .then((data) => {
                setSessionId(prev => prev = data);
                window.PagSeguroDirectPayment.setSessionId(data);
                _sessionId.current = data;
            });
    }


    const LoadPaymentMethods = (amount) => {
        _dataRef.current.payment.items[0].item.id = _formUserRef.current.GetValue("plano_id").id;
        _dataRef.current.payment.items[0].item.amount = amount;
        _dataRef.current.payment.items[0].item.quantity = 1;
        _dataRef.current.payment.items[0].item.description = _formUserRef.current.GetValue("plano_id").name;

        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: amount,
            success: function (response) {
                // Retorna os meios de pagamento disponíveis.
                setPaymentMethods(response);
                LoadCards(response);
            },
            error: function (response) {
                // Callback para chamadas que falharam.
                console.log("error", response);
                return null;
            },
            complete: function (response) {
                // Callback para todas chamadas.
            }
        });
    }


    const LoadCards = (data) => {
        if (_cardsRef.current.length > 0) return;
        let arr = data.paymentMethods.CREDIT_CARD.options;
        let html = "";

        let keys = Object.keys(arr);
        let cards = [];

        keys.map((item, i) => {
            if (arr[item].status == "AVAILABLE") {
                //console.log(arr[item]);
                html += "<span><img src='https://stc.pagseguro.uol.com.br" + arr[item].images.MEDIUM.path + "'> </span>";
                cards.push({ id: arr[item].code, name: arr[item].name });
            }
        });

        _cardsRef.current = cards;

        _formUserRef.current.Select("brand", cards, "", "", "", "");
        _formUserRef.current.Value("brand", cards[0].id);

        document.getElementById("div_paymentforms").innerHTML = html;
    }

    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.DisableFields();
                await _formUserRef.current.DisableFields(["email", "senha"], true);

                await _formUserRef.current.Value("plano_id", "1");

                let wait_session = setInterval(async () => {
                    if (_sessionId.current != "") {
                        clearInterval(wait_session);
                        await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                        _formUserRef.current.Value("uf_codigo", 26);
                        await _formUserRef.current.Select("cidade_codigo", [], "api/Cadastro/Cidades", "POST", { uf_codigo: 26 }, "");
                        let arr = _formUserRef.current.GetOptions("cidade_codigo");
                        _formUserRef.current.Value("cidade_codigo", arr[0].id);
                        LoadPaymentMethods(GetAmount());
                        GetSenderHash();
                    } else {
                        console.log("Waiting pagseguroSession");
                    }
                }, 300);
            }, 100);
        
        } catch (e) {
            
        }
        setPageLoading(false);
    }


    const plano_id = async(args) => {
        let amount = GetAmount();
        _dataRef.current.payment.items[0].item.id = _formUserRef.current.GetValue("plano_id").id;
        _dataRef.current.payment.items[0].item.amount = amount;
        _dataRef.current.payment.items[0].item.quantity = 1;
        _dataRef.current.payment.items[0].item.description = _formUserRef.current.GetValue("plano_id").name;
    }


    const blur_senha = async(args) => await blur_email(args);


    const blur_email = async(args) => {
        let senha = _formUserRef.current.GetValue("senha");
        let email = _formUserRef.current.GetValue("email");
        if(senha !== "" && senha !== undefined && email !== "" && email !== undefined) {
            await Promise.resolve(new Request().Run("api/Cadastro/GetUser", "POST", { email: email, senha: senha}))
                .then(async (data) => {
                    if (data.toString() === "error") {
                        setExistUser(false);
                        setUserInfo({});
                        await _formUserRef.current.DisableFields();
                        await _formUserRef.current.DisableFields(["email", "senha"], true);
                        window.swal("Alerta", "Usuário não encontrado", "warning");
                    } else {
                        if(data.user_id===0) {
                            setExistUser(false);
                            setUserInfo({});
                            await _formUserRef.current.DisableFields();
                            await _formUserRef.current.DisableFields(["email", "senha"], true);
                            window.swal("Alerta", "Usuário não encontrado", "warning");
                        } else {
                            data.email = email;
                            data.senha = senha;
                            setExistUser(true);
                            setUserInfo(data);
                            window.w_loadObj("#cadastro");
                            if(data.uf_codigo === "" || data.uf_codigo === 0)  {
                                data.uf_codigo = 26;
                            }
                            await _formUserRef.current.Load(data);
                            //console.log(data);
                            await _formUserRef.current.DisableFields(undefined, true);
                            await _formUserRef.current.DisableFields(["email", "senha"], false);
                            window.w_unloadAll();
                        }
                    }
                });
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers



    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        ValidCard();
    }


    const ValidCard = (event) => {
        let _validade = _formUserRef.current.GetValue("validade");
        let _cardNumber = _formUserRef.current.GetValue("cardNumber");
        let _brand = _formUserRef.current.GetValue("brand").name.toLowerCase();
        let _cvv = _formUserRef.current.GetValue("cvv");

        try {
            if (_validade.indexOf("/") == -1 || 
                _cardNumber == "" ||
                _brand == "" ||
                _cvv == "" ||
                _cardNumber == null ||
                _brand == null ||
                _cvv == null ||
                _cardNumber == undefined ||
                _brand == undefined ||
                _cvv == undefined
            ) {
                alert("Informações do cartão incorretas!");
                return;
            }

            let _month = _validade.split("/")[0];
            let _year = _validade.split("/")[1];

            createCardToken({
                cardNumber: _cardNumber,
                brand: _brand,
                cvv: _cvv,
                expirationMonth: _month,
                expirationYear: _year
            });


            
        } catch (e) { }
    }
    //#endregion Handlers


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            _formUserRef.current.Value("brand", "101");
            _formUserRef.current.Value("cardNumber", "4111111111111111");
            _formUserRef.current.Value("cvv", "123");
            _formUserRef.current.Value("validade", "12/2030");
            _formUserRef.current.Value("cardName", "Felipe Martins dos Santos");
            _formUserRef.current.Value("birthDate", "1978-05-10");
            _formUserRef.current.Value("cpf_holder", "315.757.808-10");
            await _formUserRef.current.Value("uf_codigo", 26);
            _formUserRef.current.Value("cidade_codigo", 9726);
            _formUserRef.current.Value("rua", "Rua Capitão Antonio Raposo Barreto");
            _formUserRef.current.Value("numero", "207");
            _formUserRef.current.Value("bairro", "Jardim Bela Vista");
            _formUserRef.current.Value("cep", "12091-530");
            plano_id(null);
        }
    });*/
    //#endregion Tests

    
    return (<main id="Reativar">
        <div className="container">
            <br />
            <center><span className="topTitle"><h2>{props.Title}</h2></span></center>

            <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                <div className="row">
                    <div className="col-md-12 pt-1 pb-1">
                        <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro_full.json'} execRef={Exec}></FormGenerator>
                        <div style={{clear:'both'}}></div>
                        <center>
                            <button className="btn btn-lg btn-primary mt-3" type="submit" disabled={!existUser}>Efetivar Compra</button>
                        </center>
                        <br />
                    </div> 
                </div>
            </form>
        </div>
    </main>)
}