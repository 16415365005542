import React, { useState, useContext, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { RequestGet } from '../../scripts/Request.js';
import { Context } from '../../Context/AuthContext'
import $ from 'jquery';
import './NavMenu.css';


export default function NavMenu() {
    const { user, loading } = useContext(Context);
    const [list, setList] = useState([
        {
            "name": "Início",
            "link": "/Home",
            "icon": "fa fa-home",
            "nodes": []
        }]);

    const prestineUserRef = useRef(false);

    useEffect(() => {
        if (user.token != "" && !prestineUserRef.current) {
            console.log("NavMenu Rendered");
            prestineUserRef.current = true;
            Init();
        }
    }, [user]);


    const Init = async () => {
        let _menu = (user.nivel <= 1 ? "/json/menu_admin.json" :  "/json/menu_operador.json");
        await Promise.resolve(RequestGet(_menu))
            .then((data) => {
                setList(data);
            });
    }


    const _handleMenuClick = (item, index) => {
        for (var i = 0; i < list.length; i++) {
            if (i == index) {
                $("#item" + index).addClass("active");
                $("#subitems" + index).removeClass("collapse");
            } else {
                $("#item" + i).removeClass("active");
                $("#subitems" + i).addClass("collapse");
            }
        }
    }

    return (
        <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
                <li className="nav-header">
                    <div className="dropdown profile-element">
                        <span>
                            <img alt="image" className="img-circle" src="/img/profile0.jpg" />
                        </span>
                        <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                            <span className="clear">
                                <span className="block m-t-xs">
                                    <strong className="font-bold">{user.name}</strong>
                                </span>
                            </span>
                        </a>

                    </div>
                    <div className="logo-element">
                        SV
                     </div>
                </li>


                {list.map((item, i) => (
                    <li key={'itemmenu' + i} id={'itemmenu' + i}>
                        {item.link !== "" ? (
                            <LinkContainer to={item.link} exact="true">
                                <a><i className={item.icon}></i> <span className="nav-label">{item.name}</span> <span className="fa arrow"></span></a>
                            </LinkContainer>
                        ) : (
                                <a onClick={() => _handleMenuClick(item, i)}><i className={item.icon}></i> <span className="nav-label">{item.name}</span> <span className="fa arrow"></span></a>
                            )}
                        <ul className="nav nav-second-level collapse" id={'subitems' + i}>
                            {item.nodes.map((subitem, j) => (
                                <li key={'subitem' + j}>
                                    <LinkContainer to={subitem.link} exact="true">
                                        <a>{subitem.name}</a>
                                    </LinkContainer>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}


            </ul>

        </div>
    );
}