import React, { useState, useContext, useCallback, useEffect, useRef, createRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { GoogleMap, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { useNavigate, useParams } from 'react-router-dom';
import './Cadastro.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [unidadeId, setUnidadeId] = useState("");
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);
    const [latlng, SetLatLng] = useState({ lat: -23.0286427, lng: -45.55022150000002 });
    const navigate = useNavigate();
    

    const _formUnidadeRef = useRef();
    const _formLoadedRef = useRef(false);
    const _unidadeIdRef = useRef();
    const _unidadeDataRef = useRef();
    const _marcasRef = useRef([]);
    const _userRef = useRef(user);

    let { UnidadeId } = useParams();

    //#region Effects
    useEffect(() => {
    
        if (UnidadeId) {
            _unidadeIdRef.current = UnidadeId;
            setUnidadeId(UnidadeId);
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders


    //#endregion Loaders


    //#region Form Handlers
    const on_uf_codigo = async (data) => {
        if ((_unidadeIdRef.current == "" || _unidadeIdRef.current == null || _unidadeIdRef.current == undefined) && data.length > 0) {
            await _formUnidadeRef.current.Select("cidade_codigo", [], "api/Unidades/Cidades", "POST", { uf_codigo: data[0].id }, user.token);
        }
    }


    const uf_codigo = async (value) => {
        await _formUnidadeRef.current.Select("cidade_codigo", [], "api/Unidades/Cidades", "POST", { uf_codigo: value }, user.token);
        AddressCheck();
    }


    const cidade_codigo = (value) => {
        AddressCheck();
    }


    const on_cidade_codigo = async (data) => {
        if (_unidadeIdRef.current != "" && _unidadeIdRef.current != undefined && _unidadeIdRef.current != null && data.length > 0) {
            await _formUnidadeRef.current.Value("cidade_codigo", _unidadeDataRef.current.cidade_codigo);
        }
    }


    const blur_endereco = (value) => {
        AddressCheck();
    }


    const blur_numero = (value) => {
        AddressCheck();
    }


    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token != "") {
                    clearInterval(wait_token);
                    await _formUnidadeRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");

                    if (_unidadeIdRef.current !== "" && _unidadeIdRef.current !== undefined && _unidadeIdRef.current != null) {
                        let unidade_data = await new Request().Run("api/Unidades/Load?unidade_id=" + _unidadeIdRef.current, "POST", {}, _userRef.current.token);
                        _unidadeDataRef.current = unidade_data;
                        await _formUnidadeRef.current.Load(unidade_data);
                        await _formUnidadeRef.current.Select("cidade_codigo", [], "api/Unidades/Cidades", "POST", { uf_codigo: (unidade_data.uf_codigo === 0 ? 1 : unidade_data.uf_codigo) }, _userRef.current.token);

                        let wait_map = setInterval(() => {
                            if (mapRef.current) {
                                clearInterval(wait_map);
                                if (unidade_data.latitude != "" && unidade_data.longitude != "") {
                                    SetLatLng({ lat: unidade_data.latitude, lng: unidade_data.longitude });
                                    var _latlng = new window.google.maps.LatLng(unidade_data.latitude, unidade_data.longitude);
                                    markerRef.current.setPosition(_latlng);
                                    mapRef.current.setCenter(_latlng);
                                }
                            }
                        }, 100);
                    }
                }
            }, 100);
        } catch (err) { }
    }


    const AddressCheck = () => {
        try {
            let _uf = _formUnidadeRef.current.GetValue("uf_codigo").sigla;
            let _cidade = _formUnidadeRef.current.GetValue("cidade_codigo").name;
            let _endereco = _formUnidadeRef.current.GetValue("endereco");
            let _numero = _formUnidadeRef.current.GetValue("numero") || "";

            if (_endereco && _numero && _cidade && _uf) {
                MapSearch(_endereco + ", " + _numero + ", " + _cidade + "-" + _uf);
            } else if (_endereco && _cidade && _uf) {
                MapSearch(_endereco + ", " + _cidade + "-" + _uf);
            }
        }
        catch (e) {
            
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
        let _result = await _formUnidadeRef.current.Post("api/Unidades/Save", { unidade_id: unidadeId, latitude: latlng.lat, longitude: latlng.lng}, user.token);
        setPageLoading(false);
        navigate('/Unidades/Lista');
    }



    //#endregion Handlers


    


    //#region Maps
    const containerStyle = {
        height: '450px',
        width: '100%',
        padding: 0,
        margin: 0,
    };



    let mapOptions = {
        zoom: 12,
        streetViewControl: true,
        mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
            style: 2,
            //position: google.maps.ControlPosition.LEFT_TOP
        },
        tilt: 0,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        panControl: true,
        fullscreenControlOptions: {
            position: 1
        },
        streetViewControlOptions: {
            position: 6
        },
        zoomControlOptions: {
            position: 6
        }
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCuc6MBLlqvSNTiIqClRnb3ggIkKjMMg1I",
        libraries: ["places"]
    });

    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const markerRef = useRef();
    const mapRef = useRef();

    let input;
    let searchBox;

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
        mapRef.current = map;

        let location = new window.google.maps.LatLng(latlng.lat, latlng.lng);
        setTimeout(() => {
            map.setCenter(location);
            map.setZoom(12);
            map.setOptions(mapOptions);
            input = document.getElementById('pac-input');
            searchBox = new window.google.maps.places.SearchBox(input);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

            let _marker = new window.google.maps.Marker({
                map: map,
                //icon: icon,
                draggable: true,
                title: "Local",
                position: { lat: latlng.lat, lng: latlng.lng }
            });


            markerRef.current = _marker;
            setMarker(_marker);


            window.google.maps.event.addListener(_marker, 'dragend', function () {
                GeocodePosition(_marker.getPosition());
            });


            map.addListener('bounds_changed', function () {
                searchBox.setBounds(map.getBounds());
            });


            searchBox.addListener('places_changed', function () {
                var places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                var bounds = new window.google.maps.LatLngBounds();
                places.forEach(function (place) {
                    var icon = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };


                    try {
                        SetLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                        var _latlng = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                        markerRef.current.setPosition(_latlng);
                        mapRef.current.setCenter(_latlng);
                    } catch (e) {
                        console.log(e);
                    };
                });
            });

        }, 300);

    }, []);


    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);


    const GeocodePosition = (pos) => {
        SetLatLng({ lat: pos.lat(), lng: pos.lng() });
    }


    const MapSearch = (address) => {
        console.log(address);
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                try {
                    console.log(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                    SetLatLng({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
                    var _latlng = new window.google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                    marker.setPosition(_latlng);
                    map.setCenter(latlng);
                } catch (e) { console.log(e) }
            }
        });
    }
    //#endregion Maps



    return (
        <div className="UnidadeCadastro">
            <div>
                <form name="funidade" onSubmit={(event) => _HandleSubmit('unidade', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Unidade</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg">
                                        <FormGenerator ref={_formUnidadeRef} name={'unidade'} url={'/forms/unidade.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                        <div style={{ width: '100%' }}>
                                            {isLoaded ? (
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                >
                                                    <React.Fragment>
                                                        <input id="pac-input" type="text" style={{
                                                            boxSizing: 'border-box',
                                                            border: '1px solid transparent',
                                                            width: '350px',
                                                            height: '38px',
                                                            padding: '0 12px',
                                                            borderRadius: '3px',
                                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                            fontSize: '14px',
                                                            outline: 'none',
                                                            textOverflow: 'ellipses',
                                                            position: 'absolute',
                                                            marginTop: '10px',
                                                            marginLeft: '-30px'
                                                        }}/>
                                                    </React.Fragment>
                                                </GoogleMap>
                                            ) : (<div>Carregando...</div>)}
                                        </div>
                                        <div style={{ paddingTop: '10px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Unidade" type="submit" ><i className="fa fa-check"></i> Salvar Unidade</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            
                        </div>
                    </div>
                </form>
            </div>

         
        </div>

        
    )
}