import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../scripts/Request';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Button } from "react-bootstrap";
import { HubObjects } from '../../scripts/HubObjects';
import { FormGenerator } from '../Form/FormGenerator';
import '../../libs/jstree/jstree'
import '../../libs/jstree/themes/default/style.min.css';
import './Home.css';


import Config from './SideBar/Config';
import Servers from './SideBar/Servers';
import Atividades from './SideBar/Atividades';
import Reativar from '../Login/Reativar';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPayModal, setShowPayModal] = useState(false);
    const [showCamImageModal, setShowCamImageModal] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [input, setInput] = useState({});
    const [secretkey, setSecretkey] = useState("");
    const [latlng, SetLatLng] = useState({ lat: -23.0286427, lng: -45.55022150000002 } );
    const [activities, setActivities] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState({id:"", parent:"", text:""});

    const mapLoadedRef = useRef(false);
    const mapRef = useRef();
    const markersRef = useRef([]);
    const markersPrestineRef = useRef(false);
    const markersLoadingRef = useRef(false);
    const mapPrestineRef = useRef(false);
    const currentTabRef = useRef("Cameras");

    const _formServerRef = useRef(false);
    const _serverIdRef = useRef("");
    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);

    useEffect(() => {
        if (user.token !== "" && !markersPrestineRef.current && !mapLoadedRef.current) { 
            markersPrestineRef.current = true;
            _userRef.current = user;  
        }
    }, [user]);

    /*useEffect(() => {
        setSidebar(true);
        $(".sidebar-container").removeClass("sidebar_action_open");
        $(".sidebar-container").addClass("sidebar_action_close");
    }, []);*/


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);

    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current){
            loadedRef.current = true;
            console.log("Home Initialized");

            RegisterHub();            
            LoadMarkers();

            setTimeout(() => { 
                $(".nav-tabs a").on('click',function (event) {
                    currentTabRef.current = event.target.innerText;
                    //HubObjects.Exec("CONFIG_COMPONENT_CLEAR_FORM");
                    let p = event.target.href.lastIndexOf("-");
                    let id = parseInt(event.target.href.substr(p+1, 1));
                    SelectTab(id);
                 });   
                 Tree();
                 SelectTab(2);
            }, 100); 
            
            let wait_observer = setInterval(()=> {
                if(HubObjects.ObserverExists("CAMERA_ACTIVITY_OBSERVER")) {
                    clearInterval(wait_observer);
                    HubObjects.Subscribe("CAMERA_ACTIVITY_OBSERVER", (acts) => {  
                        setActivities(acts);
                        CameraWorker(acts);
                        //forceUpdate();
                    });
                }
            }, 100)
           
        }    
     }
    //#endregion Init


    //#region Loaders
     const LoadMarkers = (ids) => {
        if(!markersLoadingRef.current) {
            markersLoadingRef.current = true;
            setIsLoading(true);
            markersRef.current = HubObjects.Get("HOME_MARKERS");

            if (markersRef.current) {
                for (let i = 0; i < markersRef.current.length; i++) {
                    //window.google.maps.event.removeListener(markersRef.current[i], 'click', function () {});
                    markersRef.current[i].setMap(null);
                    delete markersRef.current[i];
                }
                markersRef.current.length = 0;
              }

            markersRef.current = [];

            Promise.resolve(new Request().Run("api/Home/Markers", "POST", {ids: (ids !== undefined ? ids.toString() : "") }, user.token))
                .then((data) => {
                    AddMarkers(data);
                    markersLoadingRef.current = false;
                    setIsLoading(false);
                });
        }
     }
    //#endregion Loaders


    //#region Maps
    const containerStyle = {
        height: 'calc(100%-80px)',
        width: '100%',
        top: '60px',
        padding: 0,
        margin: 0,
        bottom: '40px',
        left: 0,
        right: 0,
        position: 'absolute',
        borderTop: '1px solid #A0B0d4'
    };


    let mapOptions = {
        zoom: 16,
        streetViewControl: true,
        mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
            style: 2,
            //position: google.maps.ControlPosition.LEFT_TOP
        },
        tilt: 0,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        panControl: true,
        fullscreenControlOptions: {
            position: 1
        },
        streetViewControlOptions: {
            position: 6
        },
        zoomControlOptions: {
            position: 6
        },
        styles: [{
            stylers: [{
                saturation: 30
            }]
        }]
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCuc6MBLlqvSNTiIqClRnb3ggIkKjMMg1I",
        libraries: ["places"]
    });

    const [map, setMap] = useState(null);
    let inputBox;
    let searchBox;

    const onLoad = useCallback(function callback(map) {
        //console.log("Map onLoad " + isLoaded);
        let wait_map = setInterval(() => {
            if(map !== undefined && map !== null){
                clearInterval(wait_map);
                mapRef.current = map;
                HubObjects.Set("HOME_MAP", map, component_name);
                mapLoadedRef.current = true;

                const bounds = new window.google.maps.LatLngBounds();
                map.fitBounds(bounds);
                setMap(map);

                let location = new window.google.maps.LatLng(-23.054844, -45.571224);

                map.setCenter(location);
                map.setZoom(16);
                map.setOptions(mapOptions);
                inputBox = document.getElementById('pac-input');
                searchBox = new window.google.maps.places.SearchBox(inputBox);
                map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(inputBox);


                map.addListener('bounds_changed', function () {
                    searchBox.setBounds(map.getBounds());
                });


                searchBox.addListener('places_changed', function () {
                    var places = searchBox.getPlaces();

                    if (places.length === 0) {
                        return;
                    }

                    var bounds = new window.google.maps.LatLngBounds();
                    places.forEach(function (place) {
                        var icon = {
                            url: place.icon,
                            size: new window.google.maps.Size(71, 71),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(17, 34),
                            scaledSize: new window.google.maps.Size(25, 25)
                        };


                        try {
                            SetLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                            var _latlng = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                            mapRef.current.setCenter(_latlng);
                        } catch (e) {
                            console.log(e);
                        };
                    });
                });
            } else {
                console.log("wait_map");
            }

        }, 100);
        
    }, [])


    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, [])


    const AddMarkers = (arr) => {
        let wait_map = setInterval(() => {
            mapRef.current = HubObjects.Get("HOME_MAP");
            if (mapRef.current !== undefined && mapRef.current != null) {
                clearInterval(wait_map);
                arr.map((item, i) => {
                    AddMarker(item);
                });
                console.log()
                if(arr.length > 0) mapRef.current.setCenter(new window.google.maps.LatLng(arr[0].latitude, arr[0].longitude));
            }
        }, 100);
    }

    const AddMarker = (marker) => {
        markersRef.current = HubObjects.Get("HOME_MARKERS");
        let index = markersRef.current.findIndex(m => m.pointObj.id === marker.id);
        if (index > -1) return;

        let _marker = new window.google.maps.Marker({
            map: mapRef.current,
            pointObj: marker,
            icon: {
                url: (marker.ativo === 1 ? "/img/camera42x42.png" : "/img/camera42x42_disabled.png"),
                scaledSize: new window.google.maps.Size(42, 42),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(21, 21)
            },
            draggable: false,
            title: marker.name,
            position: { lat: eval(marker.latitude.toString().replace(",", ".")), lng: eval(marker.longitude.toString().replace(",", ".")) }
        });
        markersRef.current.push(_marker);
        HubObjects.Push("HOME_MARKERS", _marker);

    
        window.google.maps.event.addListener(_marker, 'click', function () {
            SelectTab(4);
            HubObjects.Exec("CONFIG_COMPONENT_MARKER_CLICK", this.pointObj);
        });


        window.google.maps.event.addListener(_marker, 'rightclick', function () {
            if(this.pointObj.ativo === 1) {
                let _camera_id = this.pointObj.id;
                let _server_id =  HubObjects.Exec("HOME_COMPONENT_SERVERID_FROM_CAMERAID",_camera_id);
                if(_server_id !== undefined && _server_id !== null && _server_id !== "") {
                    HubObjects.Exec("CAMERA_IMAGE64_FROM_SERVER" + _server_id, _camera_id);
                } else {
                    window.swal("Alerta", "Camera desativada", "warning");
                }
            }
        });
    }
    //#endregion Maps
    

    //#region Handlers
    const _handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    })


    const _HandleSidebar = (event) => {
        if (!sidebar) {
            $(".sidebar-container").removeClass("sidebar_action_open");
            $(".sidebar-container").addClass("sidebar_action_close");
            setSidebar(true);
        } else {
            $(".sidebar-container").removeClass("sidebar_action_close");
            $(".sidebar-container").addClass("sidebar_action_open");
            setSidebar(false);
        }
    }


    const _HandleCopyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        window.swal("Sucesso", "Secret Key copiada para área de transferência", "success");
    }


    const _HandleNewSecretkey = () => {
        if(_serverIdRef.current !== "") {
            window.w_loadObj("secretkey");
            Promise.resolve(new Request().Run("api/Home/NewSecretKey", "POST", {server_id: _serverIdRef.current }, user.token))
                .then((data) => {
                    setSecretkey(data);
                    window.w_unloadAll();
                });
        }
    }
    //#endregion Handlers


    //#region Tree
    const Tree = () => {
        $.ajaxSetup({
            beforeSend: function(request) {
                request.setRequestHeader("Authorization", user.token);
            }
        });

        const customMenu = (node) => {
            let items = {}
            
            if(user.nivel > 1) return;

            if (node.id.toString().indexOf("s_") > -1) {
                items = {
                    'item1': {
                        'label': 'Reiniciar Servidor',
                        'action': (data) => {
                            let inst = $.jstree.reference(data.reference);
                            let obj = inst.get_node(data.reference);
                            let _server_id = obj.id.replace("s_","");
                            HubObjects.Exec("SERVER_RESTART" + _server_id);
                        }
                    },
                    'item2': {
                        'label': 'Excluir Servidor',
                        'action': (data) => {
                            let inst = $.jstree.reference(data.reference);
                            let obj = inst.get_node(data.reference);
                            DeleteServer(obj.id);
                        }
                    }
                }
            } else if (node.id.toString().indexOf("c_") > -1) {
                items = {
                    'item1': {
                        'label': 'Restart Camera',
                        'action': (data) => {
                            let inst = $.jstree.reference(data.reference);
                            let obj = inst.get_node(data.reference);
                            setSelectedCamera(obj);

                            let _camera_id = obj.id.replace("c_","");
                            let _server_id = obj.parent.replace("s_","");
                            HubObjects.Exec("CAMERA_RESTART_FROM_SERVER" + _server_id, _camera_id);
                        }
                    },
                    'item2': {
                        'label': 'Imagem da Camera',
                        'action': (data) => {
                            let inst = $.jstree.reference(data.reference);
                            let obj = inst.get_node(data.reference);
                            setSelectedCamera(obj);

                            let _camera_id = obj.id.replace("c_","");
                            let _server_id = obj.parent.replace("s_","");

                            HubObjects.Exec("CAMERA_IMAGE64_FROM_SERVER" + _server_id, _camera_id);
                        }
                    },
                    'item3': {
                        'label': 'Excluir Camera',
                        'action': (data) => {
                            let inst = $.jstree.reference(data.reference);
                            let obj = inst.get_node(data.reference);
                            DeleteCamera(obj.id);
                        }
                    }
                }
            }
            
            return items;
        }


         let jstree = $('#layers_tree').jstree({
            "core": {
                "animation": 1,
                "check_callback": true,
                "themes": { "stripes": false },
                'data': {
                     'url': function (node) {
                         return 'https://lifequery.org/api/Home/Tree?n=' + node.parents.length;
                     },
                     'data': function (node) {
                         return { 'id': node.id };
                     }
                 }
            },
            "types": {
                "#": {
                    "max_children": 2000,
                    "max_depth": 20,
                    "valid_children": ["root"]
                },
                /*"root": {
                    //"icon": "/static/3.3.10/assets/images/tree_icon.png",
                    "valid_children": ["default"]
                },
                "default": {
                    "valid_children": ["default", "file"]
                },
                "file": {
                    "icon": "glyphicon glyphicon-file",
                    "valid_children": []
                }*/
            },
            'checkbox': {
                three_state: true,
                /*cascade: ''*/
            },
            'contextmenu': {
                'items': customMenu,
                 select_node: false
            },
            "plugins": [
                "checkbox", "changed", "contextmenu"
            ]
        });


        jstree.bind("hover_node.jstree", function (e, data) {
            if (data.node.id.indexOf("c_") > -1/*&& data.node.state.selected*/) {
                try {
                    markersRef.current = HubObjects.Get("HOME_MARKERS");
                    mapRef.current = HubObjects.Get("HOME_MAP");
                    let marq = markersRef.current.find(v => parseInt(v.pointObj.id) === parseInt(data.node.id.replace("c_", "")));
                    mapRef.current.panTo(marq.getPosition());
                } catch (e) { 
                    console.log(e.message) ;
                    //window.location.reload(false);
                }
            }
        });


        jstree.bind('changed.jstree', function (e, data) {
            let nodes = $('#layers_tree').jstree(true).get_selected();
            let _nodes = [];
            nodes.map((item, i) => { 
                if(item.indexOf("c_") > -1) {
                    _nodes.push(item.replace("c_", ""));
                }
             });

             LoadMarkers(_nodes);
        });
    }

    //#endregion Tree


    //#region HubObjects Handlers
    const RegisterHub = () => {
        HubObjects.Set("HOME_MARKERS", [], component_name);

        HubObjects.Set("HOME_COMPONENT_ON_CAMERA_SAVE", camera => {
            let center = mapRef.current.getCenter();
            if(camera.latitude === 0 || camera.latitude === undefined) camera.latitude = center.lat();
            if(camera.longitude === 0 || camera.longitude === undefined) camera.longitude = center.lng();
            HubObjects.Exec("CONFIG_COMPONENT_UPDATE_LATLNG", camera.latitude, camera.longitude);
            $('#layers_tree').jstree("refresh");
            AddMarker(camera);
        }, component_name);


        HubObjects.Set("HOME_COMPONENT_SERVER_ID", (async server => {
            _serverIdRef.current = server.server_id;

            await _formServerRef.current.Load(server);
        }), component_name);

        HubObjects.Set("HOME_COMPONENT_ON_SERVER_CLICK", (async server => {
            _serverIdRef.current = server.id;
            setShowModal(true);

            await _formServerRef.current.Load(server);
            setSecretkey(server.code);
        }), component_name);


        HubObjects.Set("HOME_COMPONENT_ON_EDIT_MODE", (async (camera_id, editable) => {
            markersRef.current = HubObjects.Get("HOME_MARKERS");
            let _marker = await markersRef.current.find(m => m.pointObj.id === camera_id);
            if(_marker) {
                _marker.setDraggable(editable);

                if(editable) {
                    window.google.maps.event.addListener(_marker, 'dragend', function () {
                        let _pos = _marker.getPosition();
                        HubObjects.Exec("CONFIG_COMPONENT_UPDATE_LATLNG", _pos.lat(), _pos.lng());
                    });


                    _marker.glow = true;
                    _marker.oldicon = _marker.icon.url;
                    _marker.setIcon({
                        url: "/img/camera42x42_drag.png",
                        scaledSize: new window.google.maps.Size(42, 42),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(21, 21)
                    });
                    
                } else {
                    let _glows = markersRef.current.filter(item => { return item.glow === true} );
                    _glows.map((item, i) => {
                        item.glow = false;
                        _marker.setIcon({
                            url:  item.oldicon,
                            scaledSize: new window.google.maps.Size(42, 42),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(21, 21)
                        });
                    });

                   
                    try {
                        window.google.maps.event.removeListener(_marker, 'dragend', function () {});
                    } catch(e) {}
                }
            }
        }), component_name);


        HubObjects.Set("HOME_COMPONENT_CHANGE_TAB", (index) => {
            SelectTab(index);
        });


        HubObjects.Set("HOME_COMPONENT_CAMERA_IMAGE64", (base64) => {
            //$("#camera").hide();
            if(base64.length > 0) {
                _handleOpenCamImageModal();
                //$("#camera").show();
                $("#camera").attr('src', 'data:image/jpeg;base64,'+base64);
            }
        });


        HubObjects.Set("HOME_COMPONENT_CAMERA_IMAGE64_OPEN", (base64) => {
            _handleOpenCamImageModal();
            //$("#camera").hide();
            if(base64.length > 0) {
                //$("#camera").show();
                $("#camera").attr('src', 'data:image/jpeg;base64,'+base64);
            }
        });


        HubObjects.Set("HOME_COMPONENT_SERVERID_FROM_CAMERAID", (camera_id) => {
            let cam = markersRef.current.find(m => m.pointObj.id === camera_id);
            setSelectedCamera({id: cam.pointObj.id.toString(), parent: cam.pointObj.server_id.toString(), text: cam.pointObj.name.toString()});
            return cam.pointObj.server_id;
        });


        HubObjects.Set("HOME_COMPONENT_PAY_MODAL", (open) => {
            setShowPayModal(open);
        });
    }
    //#endregion HubObjects Handlers


    //#region Modal
    const _handleOpenModal = async(event) => {
        _serverIdRef.current = "";
        await _formServerRef.current.Clear(true);
        setShowModal(true);
    }


    const _handleCloseModal = (event) => {
        setShowModal(false);
    }


    const _handleOpenCamImageModal = () => {
        setShowCamImageModal(true);
    }


    const _handleCloseCamImageModal = (event) => {
        setShowCamImageModal(false);
    }


    const _HandleUpdateCamImage = (event) => {
        let obj = {...selectedCamera};
        let _camera_id = obj.id.replace("c_","");
        let _server_id = obj.parent.replace("s_","");

        HubObjects.Exec("CAMERA_IMAGE64_FROM_SERVER" + _server_id, _camera_id);
    }


    const _handleOpenPayModal = async(event) => {
        setShowPayModal(true);
    }


    const _handleClosePayModal = (event) => {
        setShowPayModal(false);
    }

    const _HandlerCadSuccess = () => {
        setShowPayModal(false);
    }
    //#endregion Modal


    //#region Servers Handlers
    const _HandleServerSubmit = async(formname, event) => {
        event.preventDefault();
        setIsLoading(true);

        let _result = await _formServerRef.current.Post("api/Home/ServerSave", { server_id: _serverIdRef.current}, user.token);
        setIsLoading(false);

        HubObjects.Exec("SERVERS_COMPONENT_UPDATE_LIST", undefined);
        HubObjects.Exec("CONFIG_COMPONENT_UPDATE_SERVERS", undefined);
        $('#layers_tree').jstree("refresh");
        setShowModal(false);
    }


    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "") {
                    clearInterval(wait_token);
                    if(_formServerRef.current) await _formServerRef.current.Select("unidade_id", [], "api/Users/Unidades", "POST", {}, _userRef.current.token);
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }


    const _HandleClear = async(event) => {
        HubObjects.Exec("CONFIG_COMPONENT_CLEAR_FORM");
    }


    const DeleteServer = (server_id) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar esse servidor? (todas cameras associadas a ele serão apagadas!)",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                Promise.resolve(new Request().Run("api/Home/DeleteServer", "POST", {server_id}, user.token))
                    .then((data) => {
                        $('#layers_tree').jstree("refresh");
                        HubObjects.Exec("SERVERS_COMPONENT_UPDATE_LIST", undefined);
                        HubObjects.Exec("CONFIG_COMPONENT_UPDATE_SERVERS", undefined);
                        window.swal("Ação concluída", "Servidor Apagado com sucesso!", "success");
                    });
            } else {
               
            }
         });


        
    }
    //#endregion Servers Handlers


    //#region Cameras Handlers
    const _HandleAddCamera = () => {
        HubObjects.Exec("CONFIG_COMPONENT_NEW", undefined);
    }

    const DeleteCamera = (camera_id) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar essa camera? (todos arquivos associadas a ela serão apagados!)",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                Promise.resolve(new Request().Run("api/Home/DeleteCamera", "POST", {camera_id}, user.token))
                    .then((data) => {
                        $('#layers_tree').jstree("refresh");
                        window.swal("Ação concluída", "Camera Apagada com sucesso!", "success");
                    });
            } else {
               
            }
         });
    }
    //#endregion Cameras Handlers


    //#region Functions 
    const SelectTab = (id) => {
        for(let i=1; i<= 4; i++) {
            if(i === id) {
                $('#tab-'+i).show();
                $('#li-'+i).addClass("active");
            } else {
                $('#tab-'+i).hide();
                $('#li-'+i).removeClass("active");
            }
        }
    }
    //#endregion Functions


    //#region Workers
    const CameraWorker = (acts) =>{
        //console.log(acts.length);
        markersRef.current.map((item, i) => {
            if(item.pointObj.ativo === 1) {
                let _activity = acts.find(a => a.camera_id === item.pointObj.id);
                if(_activity){
                    item.setIcon({
                        url: "/img/camera42x42_red.png",
                        size: new window.google.maps.Size(42, 42),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(42, 42)
                    })
                } else {
                    item.setIcon({
                        url: "/img/camera42x42.png",
                        size: new window.google.maps.Size(42, 42),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(42, 42)
                    })
                }
            }
        });
    }
    //#endregion Workers

    return (
        <div>
        <div>
        {isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                initialRegion={{
                    latitude: -23.054844,
                    longitude: -45.571224,
                    latitudeDelta: 0.1,
                    longitudeDelta: 0.1,
                }}
                onUnmount={onUnmount}
            >
                   <React.Fragment>
                        <input id="pac-input" type="text" style={{
                            boxSizing: 'border-box',
                            border: '1px solid transparent',
                            width: '350px',
                            height: '38px',
                            padding: '0 12px',
                            borderRadius: '3px',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            fontSize: '14px',
                            outline: 'none',
                            textOverflow: 'ellipses',
                            position: 'absolute',
                            marginTop: '10px',
                            marginLeft: '-30px'
                        }}/>
                    </React.Fragment>
            </GoogleMap>
        ) : (<div>Carregando...</div>)}
        </div>

        <div className="sidebar-container sidebar_action_open">
            <ul className="nav nav-tabs navs-4">
                <li id="li-1" className="active">
                    <a data-toggle="tab" href="#tab-1" ignore="true">
                        Cameras
                    </a>
                </li>
                <li id="li-2" className="">
                    <a data-toggle="tab" href="#tab-2" ignore="true">
                        Atividades
                     </a>
                </li>
                <li id="li-3" className="">
                    <a data-toggle="tab" href="#tab-3" ignore="true">
                        Servidores
                    </a>
                </li>
                <li id="li-4" className="">
                    <a data-toggle="tab" href="#tab-4" ignore="true">
                        Config
                     </a>
                </li>
            </ul>

            <div className="tab-content">
                <div id="tab-1" className="tab-pane active">
                    <div className="sidebar-title">
                       Unidades / Cameras
                    </div>

                    <div className="sidebar-content">
                        <div id="layers_tree"></div>
                    </div>
                </div>

                <div id="tab-2" className="tab-pane">
                    <div className="sidebar-title">
                        Atividades Recentes
                    </div>

                    <div className="sidebar-content" style={{padding:'5px'}}>
                        <Atividades />
                    </div>
                </div>

                <div id="tab-3" className="tab-pane">
                    <div className="sidebar-title">
                        Servidores  &nbsp; <label className={'label label-primary' + (user.user_id!==user.master_id?' hide':'')}  onClick={_handleOpenModal} style={{cursor:'pointer'}}>Novo</label>
                    </div>

                    <div className="sidebar-content" style={{padding:'5px'}}>
                        <Servers />
                    </div>
                </div>

                <div id="tab-4" className="tab-pane">
                    <div className="sidebar-title">
                        Camera / Configuração &nbsp; <label className={'label label-primary' + (user.user_id!==user.master_id?' hide':'')} onClick={_HandleAddCamera} style={{cursor:'pointer'}}>Nova</label>
                        &nbsp; <label className={'label label-primary' + (user.user_id!==user.master_id?' hide':'')} onClick={_HandleClear} style={{cursor:'pointer'}}><i className="fa fa-trash"></i></label>
                    </div>

                    <div className="sidebar-content">
                        <Config />
                    </div>
                </div>
            </div>

        </div>


        <div id="bar_btn" className="open_side">
            <div className="spin-icon right-sidebar-toggle" onClick={_HandleSidebar}>
                <i className="fa fa-arrow-circle-right"></i>
            </div>
        </div>


        <MDBModal staticBackdrop show={showCamImageModal} setShow={setShowCamImageModal} tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h3> Camera: {selectedCamera.text} </h3></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseCamImageModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <img id="camera" style={{width:'100%', height: 'auto'}}/>
                    </MDBModalBody>

                    <MDBModalFooter>
                    <Button id="btn_update" variant="primary" className="btn btn-primary" type="button" onClick={_HandleUpdateCamImage}>
                        Atualizar Imagem
                    </Button>
                    <Button variant="secondary" onClick={_handleCloseCamImageModal}>
                        Fechar
                    </Button>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
 


        <MDBModal staticBackdrop show={showModal} setShow={setShowModal} tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h3> Cadastro de Servidor </h3></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <form name="fserver" onSubmit={(event) => _HandleServerSubmit('server', event)} autoComplete="off">
                            <FormGenerator ref={_formServerRef} name={'server'} url={'/forms/server.json'} execRef={Exec}></FormGenerator>
                        </form>

                        <label>Secret Key:</label>
                        <div className="input-group m-b">
                            <input id="secretkey" type="text" class="form-control" readOnly style={{backgroundColor:'#FFFFFF'}} value={secretkey}/>
                            <div className="input-group-prepend">
                                <button className="btn btn-white" onClick={() => _HandleCopyToClipboard(secretkey)} disabled={_serverIdRef.current === ""} title="Copiar para área de transferência"><i className="fa fa-clone"></i></button>
                                <button className="btn btn-white" onClick={_HandleNewSecretkey}  disabled={_serverIdRef.current === ""} title="Gerar nova Server Key"><i className="fa fa-refresh"></i></button>
                            </div>
                        </div>
                    </MDBModalBody>

                    <MDBModalFooter>
                    <Button id="btn_save" variant="primary" className="btn btn-primary" type="button" onClick={(event) => _HandleServerSubmit('server', event)}>
                        Salvar Servidor
                    </Button>
                    <Button variant="secondary" onClick={_handleCloseModal}>
                        Fechar
                    </Button>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>


        <MDBModal id="cadastro" className='modal' staticBackdrop show={showPayModal} setShow={setShowPayModal} tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h3> Renovar plano para mais 1 mês </h3></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleClosePayModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {showPayModal ? ( <Reativar onSuccess={_HandlerCadSuccess} Title="" isOpen={showPayModal} /> ) : (<div></div>)}
                       
                    </MDBModalBody>

                    <MDBModalFooter>
                    <Button variant="secondary" onClick={_handleClosePayModal}>
                        Fechar
                    </Button>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </div>
    )
}