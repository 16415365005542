/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import './Servers.css';
import { HubObjects } from '../../../scripts/HubObjects';


export default function Servers() {
    const component_name = "admin/sidebar/servers";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const [servers, setServers] = useState([]);
    
    const _userPrestine = useRef(false);
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if (user.token !== "" && !_userPrestine.current){
            _userPrestine.current = true;
        }
    }, [user]);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current){
            loadedRef.current = true;
            console.log("Servers Initialized");

            RegisterHub();            
            //LoadServers();
        }    
    }
    //#endregion Init




    //#region Loaders
    /*const LoadServers = async() => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Home/Servers", "POST", { }, user.token))
            .then((data) => {
                setServers(data);
                setPageLoading(false);
                let wait_header = setInterval(() => {
                    let has_header = HubObjects.Exists("HEADER_SERVERS_UPDATE");
                    //console.log(has_header);

                    if(has_header) {
                        clearInterval(wait_header);
                        HubObjects.Exec("HEADER_SERVERS_UPDATE", data);
                    }
                }, 300);
            });
    }*/
    //#endregion Loaders


    //#region HubObjects Handlers
    const RegisterHub = () => {
        /*HubObjects.Set("SERVERS_COMPONENT_UPDATE_LIST", (async server => {
            LoadServers();
        }), component_name);*/

        
        HubObjects.Set("SIDEBAR_SERVERS_UPDATE", (async serverList => {
            setServers(serverList);
            forceUpdate();
        }), component_name);
    }
    //#endregion HubObjects Handlers


    //#region Handlers
    const  _HandleServerClick = (item) => {
        HubObjects.Exec("HOME_COMPONENT_ON_SERVER_CLICK", item);
    }
    //#endregion Handlers


    return (
        <div className="ServersTab">
            { servers.length > 0 ? 
            (   <div>
                {
                   servers.map((item, i) => (
                    <div key={'server'+i} className="server" onClick={()=> _HandleServerClick(item)}>
                        <div className="server_icon"><img src="/img/server42x42.png" alt=""/> </div>
                        <div className="server_name"> {item.name} </div>
                    </div>
                   ))
                }
                </div>
            ) : 
            (<div className='jumbotron'><center><i className="fa fa-cloud-upload fa-2x"></i><br /><span style={{padding:'5px'}}>Nenhum servidor encontrado</span></center></div>)
            }
        </div>
    )
}