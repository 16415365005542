import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import './Lista.css';


export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", uf_codigo: "", nivel: "" });
    const [unidades, setUnidades] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [niveis, setNiveis] = useState([
        { id: 1, name: "Administrador" },
        { id: 2, name: "Operador" }
    ]);
    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const niveisRef = useRef();
    const usersPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        niveisRef.current = [...niveis];//Object.assign({}, niveis); 
        Init();
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Loaders
    const Init = async() => {
        if(!usersPrestineRef.current){
            usersPrestineRef.current = true;
            _HandlerSearchChange(null);
            LoadUnidades();
        }
    }


    const LoadUnidades = async() => {
        setIsLoading(true);
        Promise.resolve(new Request().Run("api/Users/Unidades?nocache=" + new Date().getUTCMilliseconds(), "POST", { }, user.token))
            .then((data) => {
                setUnidades(data);
            });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandlerChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Users/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word, nivel: search.nivel }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                let _niveis = [...niveisRef.current];
                _list.map((item, i) => {
                    try {
                        item.nivel = _niveis.find(n => n.id == item.nivel).name;
                    } catch (e) {
                        item.nivel = item.nivel == 0 ? "Desenvolvedor" : "Não definido";
                    }
                });
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Users/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word, nivel: search.nivel }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    let _niveis = [...niveisRef.current];
                    _list.map((item, i) => {
                        try {
                            item.nivel = _niveis.find(n => n.id === item.nivel).name;
                        } catch (e) {
                            item.nivel = item.nivel === 0 ? "Desenvolvedor" : "Não definido";
                        }
                    });
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UserClick = (item) => {
        navigate('/Usuarios/Cadastro/'+item.user_id);
    }


    const _DisableUser = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar esse usuário?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Users/DeleteUser", "POST", { user_id: item.user_id }, user.token))
                        .then((data) => {
                            listRef.current = listRef.current.filter(u => { return u.user_id !== item.user_id } )
                            setList(listRef.current);
                            setIsLoading(false);
                        }).catch(()=> {
                            setIsLoading(false);
                        });
            } else {
               
            }
         });

        
    }

    //#endregion Handlers


    return (
        <div className="UsuarioLista">
            <div className="row">
                <div className="col-lg-8">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Lista de Usuarios</h5>
                            <label className="label label-default pull-right btn"> { total } usuarios </label>
                        </div>

                        <div className="ibox-content">
                            <div className="input-group">
                                <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={_HandlerChange} />

        
                                <span className="input-group-btn">
                                    <select id="nivel" className="input form-control" value={search.nivel} onChange={_HandlerChange}>
                                        <option value="">Qualquer Nivel</option>
                                        {niveis.map((item, i) => (
                                            <option key={'box' + i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </span>


                                <span className="input-group-btn">
                                    <button id="btnBusca" type="button" className="btn btn btn-primary" onClick={_HandlerSearchChange}> <i className="fa fa-search"></i> Busca</button>
                                </span>
                            </div>

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Nivel</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'user' + i}>
                                                    <td onClick={() => _UserClick(item)}> {item.name} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.nivel} </td>
                                                    <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DisableUser(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className="fa fa-trash"></i></button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Unidades</h5>
                        </div>

                        <div className="ibox-content">  
                            {unidades.length > 0 ? 
                            (<table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th style={{width:'32px'}}></th>
                                        <th>Nome</th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                    {unidades.map((item, i) => (
                                        <tr key={'unidade' + i}>
                                            <td style={{padding:'7px 0px 0px 0px'}}> <input type="checkbox" checked={item.selected} style={{ width: '18px', height: '18px', marginLeft:'3px' }} disabled={true} /> </td>
                                            <td> {item.name} </td>
                                        </tr>
                                    ))}
                                
            
                                </tbody>
                            </table>) :
                            (<div className='jumbotron'><center><i className="fa fa-bank fa-2x"></i><br /><span style={{padding:'5px'}}>Nenhuma unidade encontrada</span></center></div>)
                            }
                            
                        </div>
                    </div>           
                </div>
            </div>
        </div>
    );
}