import React, { useState, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubObjects } from '../../scripts/HubObjects';
import { Uuid } from '../../scripts/StringUtils';
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import $ from 'jquery';
import './Server.css';


export default function Server(props) {
    const statuses = [
        {description: "Desconectado", icon: "fa-exclamation-circle", color: "Disconnected"},
        {description: "Conectando...", icon: "fa-clock-o", color: "Connecting"},
        {description: "Conectado", icon: "fa-check-circle", color: "Connected"},
    ];
    const [status, setStatus] = useState(0);
    
    const { id, name, index, host, unidade_id, code } = props.info;

    const hostPrestineRef = useRef(false);
    const queueRef = useRef([]);
    const alertActivityRef = useRef(true);
    const voiceActivityRef = useRef(true);
    const cameras_alertsRef = useRef([]);
    const socketRef = useRef(null);
    const tryconnectRef = useRef(null);
    const timerIdleRef = useRef(new Date());

    const isSignalR = useRef(false);
    const connectionRef = useRef(null);
    const connectionUuid = useRef(Uuid());

    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        isSignalR.current = host.toLowerCase().indexOf("http") === 0;

        window.toastr.options = {
            "debug": false,
            "positionClass": "toast-bottom-left",
            "onclick": null,
            "fadeIn": 300,
            "fadeOut": 1000,
            "timeOut": 5000,
            "width": 200,
            "extendedTimeOut": 1000
          }

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    if(!isSignalR.current) {
                        socketRef.current.close();
                        socketRef.current = null;
                    } else {
                        setStatus(0);
                        props.onChange(0, index);
                        connectionRef.current.stop();
                        connectionRef.current = null;
                    }
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if(!hostPrestineRef.current && host !== undefined && host !== null && host !== "") {
            hostPrestineRef.current = true;
            TryConnect();
        }
    }, [host]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        RegisterHub();
    }
    //#endregion Init


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("CAMERA_RESTART_FROM_SERVER" + id, (camera_id) => {
            try {
                if(!isSignalR.current) {
                    socketRef.current.send("{cmd:\"start\", id:\"" +  camera_id + "\"}");
                } else {
                    connectionRef.current.invoke("Message", "{\"cmd\":\"start\", \"id\":\"" +  camera_id + "\", \"origin\":\"" + connectionUuid.current + "\"}", code, code).catch(function (err) {
                        return console.error(err.toString());
                    });
                }
            } catch(e) { }
        });


        HubObjects.Set("SERVER_RESTART" + id, () => {
            try {
                if(!isSignalR.current) {
                    socketRef.current.send("{cmd:\"exit\", id:\"\"}");
                } else {
                    connectionRef.current.invoke("Message", "{\"cmd\":\"exit\", \"id\":\"\", \"origin\":\"" + connectionUuid.current + "\"}", code, code).catch(function (err) {
                        return console.error(err.toString());
                    });
                }
            } catch(e) { }
        });


        HubObjects.Set("CAMERA_IMAGE64_FROM_SERVER" + id, (camera_id) => {
            try {
                if(!isSignalR.current) {
                    socketRef.current.send("{cmd:\"bmp\", id:\"" + camera_id + "\"}");
                } else {
                    connectionRef.current.invoke("Message", "{\"cmd\":\"bmp\", \"id\":\"" + camera_id + "\", \"origin\":\"" + connectionUuid.current + "\"}", code, code).catch(function (err) {
                        return console.error(err.toString());
                    });
                    HubObjects.Exec("HOME_COMPONENT_CAMERA_IMAGE64_OPEN", "");
                }
            } catch(e) { }
        });
    }
    //#region HubObjects


    //#region Handlers
    const TryConnect = () => {
        try {
            clearInterval(tryconnectRef.current);
        } catch(e) { }

        try {
            if(!isSignalR.current) {
                setStatus(1);
                props.onChange(1, index);
                socketRef.current = new WebSocket("ws:"+host+":9001/");
                socketRef.current.onopen = () => { 
                    setStatus(2);
                    props.onChange(2, index);
                    console.log(host + " success"); 
                };
                socketRef.current.onmessage = (msg)  => { 
                    let jso = JSON.parse(msg.data);
                    try {
                        if(msg.data.indexOf("base64") > -1) {
                            //console.log(jso);
                            HubObjects.Exec("HOME_COMPONENT_CAMERA_IMAGE64", jso.base64);
                        } else {
                            OnSurveillanceMessage(msg.data);
                        }
                    } catch(e) {
                        OnSurveillanceMessage(msg.data);
                    }  
                };
                socketRef.current.onclose = () => { 
                    setStatus(0);
                    props.onChange(0, index);
                    console.log(host + " closed"); 
                    tryconnectRef.current = setTimeout(() => { TryConnect()}, 300000);
                };
            } else {
                setStatus(1);
                props.onChange(1, index);
                console.log(host + " trying connection");
                connectionRef.current = new HubConnectionBuilder().withUrl(host).withAutomaticReconnect().build();
                connectionRef.current.start()
                    .then(() => {
                        setStatus(2);
                        props.onChange(2, index);
                        console.log(host + " success");

                        connectionRef.current.on("Message", function (message, sessionId) {
                            //console.log(message);
                            let jso = JSON.parse(message);
                            try {
                                if(message.indexOf("base64") > -1) {
                                    HubObjects.Exec("HOME_COMPONENT_CAMERA_IMAGE64", jso.base64);
                                } else {
                                    OnSurveillanceMessage(message);
                                }
                            } catch(e) {
                                OnSurveillanceMessage(message);
                            }
                        });


                        connectionRef.current.invoke("Connect", connectionUuid.current, code).catch(function (err) {
                            return console.error(err.toString());
                        });

                    }).catch((error) => console.log(error));
            }
        } catch(e) {
            setStatus(0);
            props.onChange(0, index);
            console.log(host + " closed"); 
            tryconnectRef.current = setTimeout(() => { TryConnect()}, 300000);
         }
    }


    const HandleTryConnect = () => {
        if(status === 0) {
            TryConnect();
        } else if(status === 2) {
            try {
                if(!isSignalR.current) {
                    socketRef.current.close();
                    socketRef.current = null;
                } else {
                    connectionRef.current.stop();
                    connectionRef.current = null;
                }
            } catch(e) { }
        }
    }


    const OnSurveillanceMessage = (msg) => {
        let json = null;
        let _queue = null;

        try {
            json = JSON.parse(msg.toString());
        } catch(e) {
            return;
        }


        try {
            _queue = queueRef.current.find(v => parseInt(v.camera_id) === parseInt(json.id));
            if(_queue) {
                if ((new Date().getTime() -_queue.data.getTime()) < 8000) {
                    //console.log("Escape");
                    return;
                }
            }
        } catch(e) {
            _queue = null;
        }

        if (_queue == null || _queue === undefined) {
            _queue = { camera_id: parseInt(json.id), data: new Date() };
            queueRef.current.push(_queue);
        } else {
            _queue.data = new Date();
        }

        
        if (alertActivityRef.current && _queue !== null) {
            try {
                  
                try {
                    if (cameras_alertsRef.current.length > 0) {
                        let cam = cameras_alertsRef.current.find(v => parseInt(v.camera_id) === parseInt(json.id));
                        json.name = cam.name;
                        json.alert = cam.alert;
                        json.analize = cam.analize;
                        json.motion_threshold = cam.motion_threshold;
                    } else{
                        cameras_alertsRef.push(json);
                    }
                } catch (err) { }


                if(json.predictions.length > 0){
                    let m_arr = {persons: 0, cars: 0, dogs: 0, bus: 0, trucks: 0, boats: 0, cats: 0, trains: 0, airplanes: 0};
                    let m_count = 0;
                    json.predictions.map((item, i) => {
                        if(item.Label.Name==='person') {
                            m_arr.persons++;
                            m_count++;
                        } else if(item.Label.Name==='car') {
                            m_arr.cars++;
                            m_count++;
                        } else if(item.Label.Name==='dogs') {
                            m_arr.dogs++;
                            m_count++;
                        } else if(item.Label.Name==='bus') {
                            m_arr.bus++;
                            m_count++;
                        } else if(item.Label.Name==='truck') {
                            m_arr.trucks++;
                            m_count++;
                        } else if(item.Label.Name==='boat') {
                            m_arr.boats++;
                            m_count++;
                        } else if(item.Label.Name==='cat') {
                            m_arr.cats++;
                            m_count++;
                        } else if(item.Label.Name==='train') {
                            m_arr.trains++;
                            m_count++;
                        } else if(item.Label.Name==='airplane') {
                            m_arr.airplanes++;
                            m_count++;
                        }
                    });


                    if(m_count === 0) return;
                    json.predictions = m_arr;
                } else {
                    json.predictions = null; 
                }


                if (json.alert === 1) {
                    try {
                        CameraMoviment(json);
                    } catch (e) { }
                } else if (json.alert === 2) {
                    try {
                        CameraMoviment(json);
                    } catch (e) { }
                    window.toastr.error(json.name, 'Alerta de Movimento');
                } else if (json.alert === 3) {
                    window.toastr.error(json.name, 'Alerta de Movimento');
                    Speak("Alerta de Movimento na câmera " + json.name);
                    try {
                        CameraMoviment(json);
                    } catch (e) { }
                }
            } catch (err) { }

            setTimeout(() => { alertActivityRef.current = true }, 3000);
        }
    }
    //#endregion Handlers


    //#region Functions
    const CameraMoviment = (json) => {
        //console.log(json);
        json.server_id = id;
        json.unidade_id = unidade_id;
        HubObjects.Exec("HEADER_ON_CAMERA_ACTIVITY_UPDATE", json);
    }

    const Speak = (phrase) => {
        if(voiceActivityRef.current) {
            voiceActivityRef.current = false;

            try {
                var msg = new SpeechSynthesisUtterance();
                var voices = window.speechSynthesis.getVoices();

                msg.voice = voices[0];
                msg.rate = 10 / 10;
                msg.pitch = 1;
                msg.text = phrase;

                msg.onend = function (e) {
                    console.log('Finished in ' + e.elapsedTime + ' seconds.');

                    setTimeout(() => { voiceActivityRef.current = true }, 4000);
                };

                console.log("Speak " + phrase);
                speechSynthesis.speak(msg);
            } catch (e) {
                console.log(e);
            }
        }
    }
    //#endregion Functions


    return (
        <>
            <li className={'HeaderServer dropdown-divider' + (index===0?' hide':'')}></li>
            <li className="server">
                <div className="server_lnk" onClick={HandleTryConnect}>
                    <i className={'fa ' + statuses[status].icon + ' '  + statuses[status].color}></i> &nbsp;<span className={statuses[status].color}>{name}</span>
                    <span className={'float-right text-muted small ' + statuses[status].color}>{statuses[status].description}</span>
                </div>
            </li>
        </>
    );
}