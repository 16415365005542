import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import './Lista.css';



export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", uf_codigo: "", cidade_codigo: "" });
    const [estados, setEstados] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const unidadesPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        Init();
    }, [user]);

    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Loaders
    const Init = async() => {
        if(!unidadesPrestineRef.current) {
            unidadesPrestineRef.current = true;
            await Promise.resolve(new Request().Run("/json/estados.json", "GET", '', ''))
                .then((data) => {
                    setEstados(data);
                });
            
            _HandlerSearchChange(null);
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandlerChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Unidades/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { uf_codigo: search.uf_codigo, word: search.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                listRef.current = listRef.current.concat(data.list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Unidades/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { uf_codigo: search.uf_codigo, word: search.word }, user.token))
                .then((data) => {
                    listRef.current = listRef.current.concat(data.list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UnidadeClick = (item) => {
        navigate('/Unidades/Cadastro/'+item.unidade_id);
    }


    const _DisableUnidade = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar essa unidade?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Unidades/DeleteUnidade", "POST", { unidade_id: item.unidade_id }, user.token))
                .then((data) => {
                    listRef.current = listRef.current.filter(u => { return u.unidade_id !== item.unidade_id } )
                    setList(listRef.current);
                    setIsLoading(false);
                }).catch(()=> {
                    setIsLoading(false);
                });
            } else {
               
            }
         });
    }

    //#endregion Handlers


    return (
        <div className="UnidadesLista">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Lista de Unidades</h5>
                                <label className="label label-default pull-right btn"> { total } unidades </label>
                            </div>

                            <div className="ibox-content">
                                <div className="input-group">
                                    <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={_HandlerChange} />

                                    <span className="input-group-btn">
                                        <select id="uf_codigo" className="input form-control" value={search.uf_codigo} onChange={_HandlerChange}>
                                            <option value="">Qualquer Estado</option>
                                            {estados.map((item, i) => (
                                            <option key={'box' + i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </span>


                                <span className="input-group-btn">
                                    <button id="btnBusca" type="button" className="btn btn btn-primary" onClick={_HandlerSearchChange}> <i className="fa fa-search"></i> Busca</button>
                                </span>
                            </div>

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cidade</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'unidade' + i}>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.name} </td>
                                                    <td onClick={() => _UnidadeClick(item)}> {item.cidade}-{item.uf} </td>
                                                    <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DisableUnidade(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className="fa fa-trash"></i></button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                </div>
            </div>

        </div>
    );
}