import React, { useContext, useState, useEffect, useRef} from 'react';
import $ from 'jquery';
import { Request}  from '../../../scripts/Request';
import StringMask from 'string-mask';
import { HubObjects } from '../../../scripts/HubObjects';
import './Forget.css';


const Forget = (props) => {
    const [frm, setFrm] = useState({ email: "", code: "", new_pwd: "", repeat_pwd: "", token: "" });

    const _HandlerChange = (e) => {
        const { id, value } = e.target;

        if (id === "celular") {
            let result1 = value.replace(/[^\d]+/g, '')
            let formatter = new StringMask("(99) 99999-9999");
            let result = formatter.apply(result1);
            setFrm(prevState => ({
                ...prevState,
                [id]: result
            }))
        } else  {
            setFrm(prevState => ({
                ...prevState,
                [id]: value
            }));
        }
    }

    const _HandlerSend = async(e) => {
        window.w_loadObj("#btnSend");
        await Promise.resolve(new Request().Run("api/Recovery/SendCode", "POST", frm, ''))
            .then((data) => {
                window.w_unloadAll();
                if (data.toString() === "loaded") {
                    HubObjects.Set("RECOVERY_FORM", {...frm});
                    props.onRequest();
                } else if (data.toString() === "error") {
                    window.swal("Alerta", "Ocorreu um erro na sua solicitação!", "warning");
                }
            });
    }


    return (
        <div className="Forget">
            <div style={{ height: '45px', marginTop: '20px' }}>
                <center>
                    <span className="topTitle" style={{ color: '#8469EC' }}><h2>Esqueci minha senha</h2></span>
                </center>
            </div>

            <p>
                <h4>Digite seu e-mail</h4>
                <input id="email" name="email" type="email" className="form-control" value={frm.email} onChange={_HandlerChange} autoComplete="off" />

                <div className="cad_pre_info">
                    Preencha o campo acima com seu e-mail e clique em Enviar Código, pegue o código no seu e-mail para prosseguir com a criação de uma nova senha com segurança.  
                </div>

                <button id="btnSend" className="btn btn-primary" style={{ width: '100%', height: '40px', marginTop: '15px' }} disabled={!(frm.email.indexOf("@") > -1)} onClick={_HandlerSend}> Enviar Código</button>

                <button id="btnBack" className="btn btn-white" style={{ width: '100%', height: '40px', marginTop: '15px' }} onClick={() => props.onCancel()}> Voltar </button>
            </p>

        </div>
    )
}

export default Forget;