import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubObjects } from '../../scripts/HubObjects';
import { Request } from '../../scripts/Request';
import { Button } from "react-bootstrap";
import Server from './Server';
import $ from 'jquery';
import moment from 'moment';
import './Header.css';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



export default function Header() {
    const component_name = "Header";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [state, setState] = useState({
        voiceActivity: false,
        zoom: 100
    });
    const [showModal, setShowModal] = useState(false);
    const [menubar, setMenubar] = useState(false);
    const [ddservers, setDdservers] = useState(false);
    const [servers, setServers] = useState([]);
    const [activities, setActivities] = useState([]);
    const [connectedCount, setConnectedCount] = useState(0);
    const [validateMessage, setValidateMessage] = useState({ icon: "fa-clock-o", message: "Carregando..."});

    const firstLoadRef = useRef(false);
    const activitiesLimit = 5000;
    const workerTime = 120000;
    const CameraActivitySaveTimeout = 60000;
    const timerIdleRef = useRef(new Date());
    const activitiesRef = useRef([]);
    const activitiesWorkerRef = useRef([]);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects

    useEffect(() => {
        let workers = setInterval(CameraWorker, workerTime);

        if(!firstLoadRef.current) {
            firstLoadRef.current = true;
            console.log("Header Rendered");

            RegisterHub();
            LoadServers();
            Validade();

            $(".dropdown-menu").addClass("show");
            setDdservers(true);

            setTimeout(() => {
                $(".dropdown-menu").removeClass("show");
                setDdservers(false);
            }, 3000);
        }

        return () => {
            clearInterval(workers);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.UnsubscribeAll("CAMERA_ACTIVITY_OBSERVER");
                    HubObjects.Clear(component_name);
                } catch(e) {}
            } 
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadServers = async() => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Home/Servers", "POST", { }, user.token))
            .then((data) => {
                data.map((item, i) => {
                    item.index = i;
                    item.status = 0;
                });
        
                setConnectedCount(data.filter(item => { return item.status === 2}).length);
                setServers(data);
                setPageLoading(false);
                let wait_header = setInterval(() => {
                    let has_header = HubObjects.Exists("SIDEBAR_SERVERS_UPDATE");

                    if(has_header) {
                        clearInterval(wait_header);
                        HubObjects.Exec("SIDEBAR_SERVERS_UPDATE", data);
                    }
                }, 300);
            });
    }
    //#endregion Loaders


    //#region Handlers
    const _FullScreen = () => {
        if (!$("body").is(":fullscreen")) {
            document.body.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }


    const _HideMenuClick = () => {
        if (!menubar) {
            $("body").addClass("mini-navbar");
            setMenubar(true);
        } else {
            $("body").removeClass("mini-navbar");
            setMenubar(false);
        }
    }


    const _ChangeMicrophone = () => {

    }


    const _Exit = () => {
        handleLogout();
    }


    const _HandlerServersList = () => {
        if(!ddservers){
            $(".dropdown-menu").addClass("show");
            setDdservers(true);
        } else {
            $(".dropdown-menu").removeClass("show");
            setDdservers(false);
        }
    }


    const ToServers = () => {
        $(".dropdown-menu").removeClass("show");
        setDdservers(false);
        HubObjects.Exec("HOME_COMPONENT_CHANGE_TAB", 3);
    }
    //#endregion Handlers


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Observer("CAMERA_ACTIVITY_OBSERVER");

        /*HubObjects.Set("HEADER_SERVERS_UPDATE", (servers) => {
            servers.map((item, i) => {
                item.index = i;
                item.status = 0;
            });
    
            setConnectedCount(servers.filter(item => { return item.status === 2}).length);
            setServers(servers);
        }, component_name);*/
    
     
        HubObjects.Set("SERVERS_COMPONENT_UPDATE_LIST", (async server => {
            LoadServers();
            forceUpdate();
        }), component_name);

    
        HubObjects.Set("HEADER_SERVERS", () => {
            return [...servers];
        });
    
    
        HubObjects.Set("HEADER_ON_CAMERA_ACTIVITY_UPDATE", (data) => {
            let index = activitiesRef.current.findIndex(c => c.camera_id === data.id)
            let message = "Movimento detectado";
            //console.log(data.predictions);
            /*if(data.predictions.length > 0) {
                let m_arr = {persons: 0, cars: 0, dogs: 0};
                data.predictions.map((item, i) => {
                    if(item.Label.Name==='person') {
                        m_arr.persons++;
                    } else if(item.Label.Name==='car') {
                        m_arr.cars++;
                    } else if(item.Label.Name==='dogs') {
                        m_arr.dogs++;
                    }
                      
                });

                if(m_arr.persons === 0 && m_arr.cars === 0 && m_arr.dogs === 0) return;
                message = m_arr.persons + " pessoas, " + m_arr.cars + " carros, " + m_arr.dogs + " cachorros" ;
            }*/

            if(data.predictions !== null) {
                message = "";
                if(data.predictions.persons > 0){
                    message += data.predictions.persons + " pessoas, "
                }

                if(data.predictions.cars > 0){
                    message += data.predictions.cars + " carros, "
                }

                if(data.predictions.bus> 0){
                    message += data.predictions.bus + " ônibus, "
                }

                if(data.predictions.trucks > 0){
                    message += data.predictions.trucks + " caminhões, "
                }

                if(data.predictions.boats > 0){
                    message += data.predictions.boats + " barcos, "
                }

                if(data.predictions.dogs > 0){
                    message += data.predictions.dogs + " cães, "
                }

                if(data.predictions.cats > 0){
                    message += data.predictions.cats + " gatos, "
                }

                if(data.predictions.trains > 0){
                    message += data.predictions.trains + " trens, "
                }

                if(data.predictions.airplanes > 0){
                   message += data.predictions.airplanes + " aviões, "
                }

                if(message.length > 0) message = message.substr(0, message.length-2);
            }

            if(index > -1) {
                activitiesRef.current[index] = {camera_id: data.id, name: data.name, predictions: data.predictions, message: message, datetime: new Date() };
            } else {
                activitiesRef.current.push({camera_id: data.id, name: data.name, predictions: data.predictions, message: message, datetime: new Date() });
            }

            CameraActivityWorker({camera_id: data.id, server_id: data.server_id, unidade_id: data.unidade_id, message: message, datetime: new Date()});

            if( activitiesRef.current.length > activitiesLimit)  activitiesRef.current.splice(activitiesLimit,  activitiesRef.current.length - activitiesLimit);
            HubObjects.Notify("CAMERA_ACTIVITY_OBSERVER",  activitiesRef.current);
        }, component_name);
    }
    //#endregion HubObjects


    //#region Server Object
    const HandlerServerChange = (status, index) => {
        let _servers = [...servers];
        _servers[index].status = status;
        setConnectedCount(_servers.filter(item => { return item.status ===2 }).length);
        setServers(_servers);
    }
    //#endregion Server Object


    //#region Modal
    const _handleOpenModal = async(event) => {
        //setShowModal(true);
        //HubObjects.Exec("HOME_COMPONENT_PAY_MODAL", true);
    }


    const _handleCloseModal = (event) => {
        //setShowModal(false);
        //HubObjects.Exec("HOME_COMPONENT_PAY_MODAL", false);
    }

    const _HandlerCadSuccess = () => {
        //setShowModal(false);
        //HubObjects.Exec("HOME_COMPONENT_PAY_MODAL", false);
    }
    //#endregion Modal


    //#region Workers
    const CameraWorker = () => {
        let c = activitiesRef.current.length;
        activitiesRef.current = activitiesRef.current.filter(item => { return ((new Date().getTime() - item.datetime.getTime()) < workerTime) } );
        //console.log(activitiesRef.current.length);
        if(c !== activitiesRef.current.length) HubObjects.Notify("CAMERA_ACTIVITY_OBSERVER",  activitiesRef.current);
    }


    const CameraActivityWorker = (activity) => {
        let index = activitiesWorkerRef.current.findIndex(c => c.camera_id === activity.camera_id);
        if(index === -1) { 
            activitiesWorkerRef.current.push(activity);
            CameraActivitySave(activity);
        } else {
            //console.log((new Date().getTime() - activitiesWorkerRef.current[index].datetime.getTime()));
            if((new Date().getTime() - activitiesWorkerRef.current[index].datetime.getTime()) > CameraActivitySaveTimeout){
                activitiesWorkerRef.current[index] = activity;
                CameraActivitySave(activity);  
            }
        }
    }


    const CameraActivitySave = async(camera) => {
        //console.log("CameraActivitySave", camera);

        await Promise.resolve(new Request().Run("api/Header/CameraActivity", "Post", camera, user.token))
        .then((data) => {
            return data;
        });

    }
    //#endregion Workers



    //#region Functions
    const Validade = () => {
        if(user.validade !== "") {
            let a = moment(user.validade);
            let b = moment(new Date());
            let _diff = a.diff(b, 'days');

            if(_diff < 0) {
                setValidateMessage({icon: "fa-warning", message: "Acesso expirado a " + Math.abs(_diff) + " dias"});
            } else if(_diff === 0) {
                setValidateMessage({icon: "fa-warning", message: "Acesso encerra em Hoje"});
            } else if(_diff <= 7) {
                setValidateMessage({icon: "fa-warning", message: "Acesso encerra em " + _diff + " dias"});
            } else {
                setValidateMessage({icon: "fa-check", message: "Validade até " + a.format('DD/MM/YYYY')});
            }
        }
    }
    //#endregion Functions



    return (<nav className="navbar navbar-static-top Header" role="navigation">
        <div className="navbar-header">
            <a className="navbar-minimalize minimalize-styl-2 btn btn-primary" onClick={_HideMenuClick} style={{ width: '40px' }}><i className="fa fa-bars"></i></a>
            <div className="btn-group">
                <a className="navbar-minimalize minimalize-styl-2 btn btn-white dropdown-toggle" data-toggle="dropdown"   onClick={_HandlerServersList}><i className="fa fa-cloud"></i> &nbsp; {connectedCount} {connectedCount===1?'Conexão':'Conexões'} &nbsp;</a>
                <ul id="ddservers" className="dropdown-menu">
                    {
                        servers.map((item, i) => (
                            <Server key={'serv'+i} info={item} onChange={HandlerServerChange}/>
                        ))
                    }

                    <li className="dropdown-divider"></li>
                    <li>
                        <div className="text-center link-block server" onClick={ToServers}>
                            <strong>Ver Servidores</strong> &nbsp;
                            <i className="fa fa-angle-right"></i>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="btn-group">
                <a className="navbar-minimalize minimalize-styl-2 btn btn-primary" href="https://pay.lifequery.org" target="_blank" /*onClick={_handleOpenModal}*/><i className={'fa '+validateMessage.icon}></i> &nbsp; {validateMessage.message}&nbsp;</a>
            </div>

            <div className="btn-group">
                <a className="navbar-minimalize minimalize-styl-2 btn btn-white" href="https://experienceleague.adobe.com/docs/target/using/experiences/vec/troubleshoot-composer/mixed-content.html?lang=pt-BR" target="_blank">Conectar servidores sem SSL</a>
            </div>

            <div className="btn-group">
                <a className="navbar-minimalize minimalize-styl-2 btn btn-white" href="/SurveillanceAIv2.rar" target="_blank">Download Server</a>
            </div>
        </div>


        <ul className="nav navbar-top-links navbar-right">
            <li>
                <a className="right-sidebar-toggle" onChange={_ChangeMicrophone}>
                    <i className={'fa fa-microphone' + (state.voiceActivity ? ' ' : ' hide')}></i>
                    <i className={'fa fa-microphone-slash' + (!state.voiceActivity ? ' ' : ' hide')}></i>
                </a>
            </li>

            <li>
                <a>
                    <span className="m-r-sm text-muted welcome-message">Surveillance A.I v2.0 </span>
                </a>
            </li>

            <li>
                <a onClick={_FullScreen}>
                    <i className="fa fa-external-link"></i>
                </a>
            </li>

            <li>
                <a onClick={_Exit}>
                    <i className="fa fa-sign-out"></i> Sair
                </a>
            </li>
        </ul>

       
    </nav>);
}