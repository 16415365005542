import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Request } from '../../scripts/Request';
import { FormGenerator } from '../Form/FormGenerator';
import { Uuid, RemoveAcentos } from '../../scripts/StringUtils';
import { Cache } from '../../scripts/Cache';

import './Cadastro.css';
import { Context } from '../../Context/AuthContext'


export default function Cadastro(props) {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(true);
    const [componentCad, setComponentCad] = useState(this);
    const [existUser, setExistUser] = useState(true);
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    


     //#region Effects
     useEffect(() => {
        if(pageLoading){
             //window.w_loadObj("#Cadastro");
        } else {
             window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects



    //#region Loaders
   
    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Value("plano_id", "1");
            }, 100);
        
        } catch (e) {
            
        }
        setPageLoading(false);
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }


    const blur_celular = async(args) => await blur_email(args);

    const blur_cpf = async(args) => await blur_email(args);


    const blur_email = async(args) => {
        let celular = _formUserRef.current.GetValue("celular");
        let email = _formUserRef.current.GetValue("email");
        let cpf = _formUserRef.current.GetValue("cpf");
        await Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", { email: email, celular: celular, cpf: cpf}))
            .then((data) => {
                if (data.toString() === "exists") {
                    setExistUser(true);

                    window.swal("Alert", "Usuário já existente, o email ou celular ou cpf já estão cadastrados no sistema!", "warning");
                    _formUserRef.current.Value("celular", "");
                    _formUserRef.current.Value("email", "");
                    _formUserRef.current.Value("cpf", "");
                } else if (data.toString() === "loaded") {
                    setExistUser(false);
                }
            });
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Cadastro/SaveNewUser", { }, "");
        setPageLoading(false);


        if(_result === "loaded") {
            _formUserRef.current.Clear();
            
            //window.swal("Sucesso", "Usuário cadastrado com sucesso! Tente efetuar seu login em alguns minutos.", "success");

            window.swal({
                title: "Sucesso",
                text: "Usuário cadastrado com sucesso! Tente efetuar seu login em alguns minutos.",
                type: "success",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                closeOnConfirm: true,
                closeOnCancel: false
            },
             function(isConfirm){
                props.onSuccess();
            });
        }

    }
    //#endregion Handlers


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            _formUserRef.current.Value("name", "Felipe Martins dos Santos");
            _formUserRef.current.Value("celular", "(12) 98122-1044");
            _formUserRef.current.Value("email", "comprador@sandbox.pagseguro.com.br");
            _formUserRef.current.Value("cpf", "315.757.808-11");
            _formUserRef.current.Value("senha", "1234567");
            _formUserRef.current.Value("plano_id", "2");
        }
    });*/
    //#endregion Tests


    return (<main id="Cadastro">
        <div className="container">
            <br />
            <center><span className="topTitle"><h2>Faça seu cadastro</h2></span></center>

            <form name="fuser" onSubmit={(event) => _HandleSubmit('cadastro', event)} autoComplete="off" id="form-contact" className="" noValidate>
                <div className="row">
                    <div className="col-md-12 pt-1 pb-1">
                        <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                        <center>
                            <button className="btn btn-lg btn-primary mt-3" type="submit" disabled={existUser}>Efetivar Compra</button>
                        </center>
                    </div> 
                </div>
            </form>
        </div>
    </main>)
}